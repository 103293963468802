// InventorySession.js

// ======================================================
// IMPORTS
// ======================================================
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import { useAuth } from '../contexts/AuthContext';
import { useInventory } from '../contexts/InventoryContext';
import { SERVER_VIDEO_CONSTRAINTS, CODEC } from '../config/videoConfig';
import { jwtDecode } from 'jwt-decode';
import InfoButton from './InfoButton';
import RackInfoDisplay from './RackInfoDisplay';
import MissingProductModal from './MissingProductModal';
import ShelfGrid from './ShelfGrid';
import VideoOverlay from './VideoOverlay';
import StreamStats from './StreamStats';
import StatsPanel from './StatsPanel';

// ======================================================
// COMPONENT INTERFACE
// ======================================================
/**
 * InventorySession Component
 * Manages real-time inventory with video stream and quantity tracking
 * 
 * @param {Object} props
 * @param {Object} props.rack - Rack information including shelves and products
 * @param {Function} props.onBack - Callback function for navigation
 * @param {Function} props.updateQuantity - Callback function for quantity updates
 * @param {Object} props.userContext - User context information
 */
const InventorySession = ({ 
  rack, 
  onBack, 
  updateQuantity,
  userContext,
  mode = 'tagzone',
}) => {
  // ======================================================
  // CONTEXTS
  // ======================================================
  const { socket, sendMessage, subscribe } = useWebSocket();
  const { user } = useAuth();
  const { 
    inventorySessionId, 
    setInventorySessionId, 
    lastActiveSessionId, 
    setLastActiveSessionId 
  } = useInventory();

  // ======================================================
  // REFS
  // ======================================================
  const streamRef = useRef(null);
  const videoRef = useRef(null);

  // ======================================================
  // STATES
  // ======================================================
  // Session States
  const [isSessionActive, setIsSessionActive] = useState(false);
  const [inventoryStarted, setInventoryStarted] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [shouldShowVideo, setShouldShowVideo] = useState(true);

  // Video States
  const [isLoading, setIsLoading] = useState(false);

  // Torch States
  const [isTorchOn, setIsTorchOn] = useState(false);
  const [torchCapability, setTorchCapability] = useState(false);
  const [isIOS, setIsIOS] = useState(false);

  // UI States
  const [showHeader, setShowHeader] = useState(true);
  const [quantities, setQuantities] = useState({});
  const [updatedProducts, setUpdatedProducts] = useState({});
  const [productUpdateTimestamps, setProductUpdateTimestamps] = useState({});
  const [shelfUpdateTimestamps, setShelfUpdateTimestamps] = useState({});
  const [sortOption, setSortOption] = useState('lastUpdate');

  // States for missing product modal
  const [showMissingProductModal, setShowMissingProductModal] = useState(false);
  const [selectedShelfForMissing, setSelectedShelfForMissing] = useState(null);
  const [missingProductResponse, setMissingProductResponse] = useState(null);

  const [activeZones, setActiveZones] = useState([]);
  const [detectionCoordinates, setDetectionCoordinates] = useState(null);

  // State for stream statistics
  const [streamStats, setStreamStats] = useState({
    fps: 0,
    bitrate: 0,
    resolution: { 
      width: 0, 
      height: 0 
    }
  });

  const [showStats, setShowStats] = useState(false);
  const [smoothedLatency, setSmoothedLatency] = useState(0);

  const handleStatsUpdate = useCallback((newStats) => {
    // console.log('Receiving new stats in InventorySession:', newStats);
    setStreamStats(newStats);
  }, []);

  // ======================================================
  // UTILITY FUNCTIONS
  // ======================================================
  /**
   * Truncates text to specified length
   */
  const truncate = (text, length = 30) => {
    return text.length > length ? text.substring(0, length) + '...' : text;
  };

  /**
   * Checks if a stored session is valid
   */
  const checkStoredSession = useCallback(async (sessionId) => {
    return new Promise((resolve) => {
      const unsubscribes = [];

      const handleSessionValid = () => {
        unsubscribes.forEach(unsub => unsub());
        resolve(true);
      };

      const handleSessionInvalid = () => {
        unsubscribes.forEach(unsub => unsub());
        resolve(false);
      };

      unsubscribes.push(subscribe('session_valid', handleSessionValid));
      unsubscribes.push(subscribe('session_not_found', handleSessionInvalid));
      unsubscribes.push(subscribe('session_expired', handleSessionInvalid));

      sendMessage({
        type: 'check_session',
        inventory_session_id: sessionId
      });

      // Set a timeout in case no response is received
      setTimeout(() => {
        unsubscribes.forEach(unsub => unsub());
        resolve(false);
      }, 5000);
    });
  }, [sendMessage, subscribe]);

  // ======================================================
  // INVENTORY MANAGEMENT
  // ======================================================
  /**
   * Handles quantity changes for products
   */
  const handleQuantityChange = (shelfId, productName, change) => {
    const key = `${shelfId}-${productName}`;
    
    setQuantities(prev => {
      const newQuantity = Math.max(0, (prev[key] || 0) + change);
      
      // Send update to backend
      if (socket && socket.readyState === WebSocket.OPEN) {
        const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

        const message = {
          type: 'adjusted_quantity',
          data: {
            shelf_id: shelfId.toString(),
            product_name: productName,
            adjusted_quantity: newQuantity.toString(),
            adjustment_reason: change > 0 ? "User increased quantity" : "User decreased quantity",
            inventory_session_id: sessionIdToUse
          }
        };
  
        sendMessage(message);
      }
  
      return {
        ...prev,
        [key]: newQuantity
      };
    });
  };

  /**
   * Sends the complete inventory update to the backend
   */
  const sendInventoryUpdate = () => {
    const sessionIdToUse = isSessionActive ? inventorySessionId : lastActiveSessionId;

    if (!sessionIdToUse) {
      console.error('No inventory session ID available');
      alert('Error: No active inventory session. Please start a new session.');
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN) {
      let userId = userContext?.user_id;
      if (!userId) {
        console.error('User ID not found in userContext');
        alert('User ID not found. Cannot send inventory update.');
        return;
      }

      const inventoryData = [];
      rack.shelves.forEach(shelf => {
        shelf.expected_products.forEach(product => {
          const key = `${shelf.id}-${product.name}`;
          const quantity = quantities[key] || 0;
          inventoryData.push({
            shelf_id: shelf.id,
            product_name: product.name,
            product_id: product.id,
            quantity: quantity.toString(),
            entity_id: shelf.entity_id,
            storage_area_id: shelf.storage_area_id,
            rack_id: rack.id,
            updated_by: userId,
          });
        });
      });

      const message = {
        type: 'update_stock',
        user_id: userId,
        inventory_data: inventoryData,
        inventory_session_id: sessionIdToUse
      };

      sendMessage(message);
    }
  };

  // Handler for missing product submission
  const handleMissingProductSubmit = async (data) => {
    const sessionIdToUse = inventorySessionId || lastActiveSessionId;

    if (!sessionIdToUse) {
        console.error('No active session ID available inside handleMissingProductSubmit');
        // Retourner une promesse rejetée pour que le composant modal puisse la gérer
        return Promise.reject(new Error('No active inventory session available'));
    }

    if (!socket || socket.readyState !== WebSocket.OPEN) {
        console.error('No socket connection available');
        return Promise.reject(new Error('No connection available'));
    }

    // Retrieve IDs from the first shelf
    const firstShelf = rack.shelves[0];
    const entityId = firstShelf?.entity_id;
    const storageAreaId = firstShelf?.storage_area_id;

    if (!entityId || !storageAreaId) {
        console.error('Missing required rack information:', rack);
        return Promise.reject(new Error('Missing required rack information'));
    }

    return new Promise((resolve, reject) => {
        const unsubscribes = [];

        const handleResponse = (response) => {
            unsubscribes.forEach(unsub => unsub());
            resolve(response);
        };

        const handleError = (error) => {
            unsubscribes.forEach(unsub => unsub());
            reject(error);
        };

        unsubscribes.push(subscribe('missing_product_response', handleResponse));
        unsubscribes.push(subscribe('missing_product_error', handleError));

        const message = {
            type: 'missing_product',
            data: {
                shelf_id: data.shelf_id,
                product_id: data.product_id,
                product_description: data.product_description,
                missing_quantity: data.missing_quantity,
                comment: data.comment || '',
                timestamp: data.timestamp,
                user_id: data.user_id,
                user_name: data.user_name,
                inventory_session_id: sessionIdToUse,
                rack_id: rack.id,
                entity_id: entityId,
                storage_area_id: storageAreaId
            }
        };

        sendMessage(message);

        // Timeout après 5 secondes
        setTimeout(() => {
            unsubscribes.forEach(unsub => unsub());
            reject(new Error('Request timed out'));
        }, 5000);
    });
};

  // Utility function to render the missing product button for a shelf
  const renderShelfMissingButton = (shelf) => (
    <button 
      className="button is-warning is-light ml-2"
      onClick={(e) => {
        e.stopPropagation();
        setSelectedShelfForMissing(shelf.id);
        setShowMissingProductModal(true);
      }}
      title="Report a missing product in this area"
    >
      <span className="icon">
        <i className="fas fa-exclamation-triangle"></i>
      </span>
      <span>Missing product?</span>
    </button>
  );

  // ======================================================
  // SESSION HANDLERS
  // ======================================================
  /**
   * Handles starting the inventory session
   */
  const handleStartInventory = async () => {
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    // Always clean up when starting
    setQuantities({});
    localStorage.removeItem('inventoryQuantities');
    setShelfUpdateTimestamps({});
    setProductUpdateTimestamps({});
    setUpdatedProducts({});

    setIsVideoActive(true);
    setShouldShowVideo(true);
    await actualHandleStartInventory();
  };

  /**
   * Handles the actual process of starting the inventory session
   */
  const actualHandleStartInventory = useCallback(async () => {
    console.log('[InventorySession] - actualHandleStartInventory');
    if (!user || !user.token) {
      console.error('User is not authenticated. Cannot start inventory session.');
      return;
    }

    setIsLoading(true);

    try {
      // Check for existing session
      const storedId = localStorage.getItem('inventorySessionId');
      if (storedId) {
        const isValid = await checkStoredSession(storedId);
        if (isValid) {
          setInventorySessionId(storedId);
          setInventoryStarted(true);
          setIsLoading(false);
          return;
        } else {
          localStorage.removeItem('inventorySessionId');
        }
      }

      // Start new session
      const decodedToken = jwtDecode(user.token);
      const sub = decodedToken.sub;
      
      if (!userContext?.user_id) {
        console.error('No user_id in context');
        alert('User ID not found. Cannot start inventory session.');
        setIsLoading(false);
        return;
      }

      // Request new session from backend
      const newSessionId = await new Promise((resolve, reject) => {
        const unsubscribes = [];

        const handleResponse = (data) => {
          unsubscribes.forEach(unsub => unsub());
          resolve(data.inventory_session_id);
        };

        const handleError = (data) => {
          unsubscribes.forEach(unsub => unsub());
          reject(new Error(data.message));
        };

        unsubscribes.push(subscribe('start_inventory_session_response', handleResponse));
        unsubscribes.push(subscribe('start_inventory_session_error', handleError));

        console.log('[InventorySession] - Sending start_inventory_session message');

        sendMessage({
          type: 'start_inventory_session',
          sub: sub,
          user_id: userContext.user_id,
          width: SERVER_VIDEO_CONSTRAINTS.width.ideal,
          height: SERVER_VIDEO_CONSTRAINTS.height.ideal,
          user_agent: navigator.userAgent,
          codec: CODEC,
          rack_id: rack.id,
          mode: mode
        });

        // Optionally, set a timeout
        setTimeout(() => {
          unsubscribes.forEach(unsub => unsub());
          reject(new Error('Timeout waiting for start_inventory_session_response'));
        }, 5000);
      });

      setInventorySessionId(newSessionId);
      localStorage.setItem('inventorySessionId', newSessionId);
      localStorage.setItem('rackId', rack.id);

      await new Promise(resolve => setTimeout(resolve, 0));

      setInventoryStarted(true);
      
    } catch (error) {
      console.error('Error in actualHandleStartInventory:', error);
      setIsLoading(false);
      setInventoryStarted(false);
      alert('Failed to start inventory session: ' + error.message);
    }
  }, [user, sendMessage, setInventorySessionId, rack, checkStoredSession, userContext, subscribe]);

  /**
   * Handles stopping the inventory session
   */
  const handleStopInventory = async () => {
    try {
        setIsSessionActive(false);
        setIsVideoActive(false);
        setShouldShowVideo(false);

        // Cleanup torch
        if (isTorchOn) {
            await turnOffTorch();
            setIsTorchOn(false);
        }

        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
            streamRef.current = null;
        }

        if (videoRef.current) {
            videoRef.current.srcObject = null;
        }

        setIsLoading(false);
        
        // Send end session message
        if (inventorySessionId && socket && socket.readyState === WebSocket.OPEN) {
            let timeoutId;
            try {
                await Promise.race([
                    new Promise((resolve, reject) => {
                        const unsubscribes = [];

                        const handleResponse = () => {
                            unsubscribes.forEach(unsub => unsub());
                            clearTimeout(timeoutId);
                            resolve();
                        };

                        unsubscribes.push(subscribe('end_inventory_session_response', handleResponse));

                        sendMessage({
                            type: 'end_inventory_session',
                            inventory_session_id: inventorySessionId
                        });

                        timeoutId = setTimeout(() => {
                            unsubscribes.forEach(unsub => unsub());
                            reject(new Error('End session timeout'));
                        }, 5000);
                    })
                ]);
            } catch (error) {
                console.error('Error stopping session:', error);
            } finally {
                clearTimeout(timeoutId);
            }
        }

    } catch (error) {
        console.error('Error stopping inventory:', error);
    } finally {
        setInventorySessionId(null);
        setInventoryStarted(false);
        setLastActiveSessionId(inventorySessionId);
    }
  };

  // ======================================================
  // TORCH CONTROL FUNCTIONS
  // ======================================================
  /**
   * Toggles the torch/flashlight state
   */
  const toggleTorch = async () => {
    if (!inventoryStarted || !streamRef.current) return;

    setIsTorchOn(prevState => !prevState);

    try {
      const track = streamRef.current.getVideoTracks()[0];
      const capabilities = track.getCapabilities();

      if (!capabilities.torch) {
        console.log("Torch is not supported on this device");
        return;
      }

      if (isIOS) {
        // iOS specific handling
        const currentConstraints = track.getConstraints();
        await track.stop();
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: {
            ...currentConstraints,
            advanced: [{ torch: !isTorchOn }]
          }
        });
        streamRef.current = newStream;
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
      } else {
        // Standard handling for other devices
        await track.applyConstraints({
          advanced: [{ torch: !isTorchOn }]
        });
      }
    } catch (err) {
      console.error("Error toggling torch:", err);
      setIsTorchOn(false);
    }
  };

  /**
   * Turns off the torch/flashlight
   */
  const turnOffTorch = async () => {
    if (!streamRef.current || !torchCapability) return;

    try {
      if (isIOS) {
        // iOS specific cleanup
        streamRef.current.getVideoTracks().forEach(track => track.stop());
      } else {
        // Standard cleanup for other devices
        const track = streamRef.current.getVideoTracks()[0];
        await track.applyConstraints({
          advanced: [{ torch: false }]
        });
      }
      setIsTorchOn(false);
    } catch (err) {
      console.error("Error turning off torch:", err);
    }
  };

  // ======================================================
  // EFFECTS
  // ======================================================
  // Cleanup on component unmount
  useEffect(() => {
    return () => {
      // Nettoyer les états locaux
      localStorage.removeItem('inventoryQuantities');
      setShelfUpdateTimestamps({});
      setProductUpdateTimestamps({});
      setUpdatedProducts({});
      // setQuantities({});
      // setActiveZones([]);

      // Informer le backend que la session se termine
      // seulement si la socket est ouverte et qu'on a un ID de session
      if (socket?.readyState === WebSocket.OPEN && inventorySessionId) {
        console.log('[InventorySession] Sending end session message');
        sendMessage({
          type: "end_inventory_session",
          data: { 
            inventory_session_id: inventorySessionId 
          }
        });
      }
    };
  }, [socket, inventorySessionId, sendMessage]);

  // Initialize tagzone mode
  useEffect(() => {
    if (socket && inventorySessionId) {
      console.log('[InventorySession] Initializing tagzone mode');
      sendMessage({
        type: "change_mode",
        data: {
          mode: "tagzone",
          inventory_session_id: inventorySessionId
        }
      });
    }
  }, [socket, inventorySessionId, sendMessage]);

  // Cleanup on window refresh/close
  useEffect(() => {
    const handleBeforeUnload = () => {
      setQuantities({});
      localStorage.removeItem('inventoryQuantities');
      setShelfUpdateTimestamps({});
      setProductUpdateTimestamps({});
      setUpdatedProducts({});
      setActiveZones([]);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  
  // Load saved quantities on mount
  useEffect(() => {
    const savedQuantities = localStorage.getItem('inventoryQuantities');
    if (savedQuantities) {
      setQuantities(JSON.parse(savedQuantities));
    }
  }, []);

  // Save quantities when they change
  useEffect(() => {
    if (Object.keys(quantities).length > 0) {
      localStorage.setItem('inventoryQuantities', JSON.stringify(quantities));
    }
  }, [quantities]);

  // Initialize rack data
  useEffect(() => {
    if (rack && rack.shelves) {
      // Rack and shelves are available
    } else {
      console.error("Rack or shelves are undefined");
    }
  }, [rack]);

  // Handle header visibility
  useEffect(() => {
    const headerSection = document.querySelector('.section.has-background-white');
    if (headerSection) {
      headerSection.classList.add('is-hidden');
    }

    return () => {
      if (headerSection) {
        headerSection.classList.remove('is-hidden');
      }
    };
  }, [rack, inventoryStarted]);

  // WebSocket message subscriptions
  useEffect(() => {
    if (!socket) return;

    const unsubscribes = [];

    // Handle 'inventory' messages
    unsubscribes.push(subscribe('inventory', (data) => {
      try {
        if (data.data) {
          Object.entries(data.data).forEach(([shelfId, shelfData]) => {
            if (shelfData.products && typeof shelfData.products === 'object') {
              let shelfUpdated = false;
              Object.entries(shelfData.products).forEach(([productName, productData]) => {
                const key = `${shelfId}-${productName}`;
                setQuantities(prev => {
                  const oldCount = prev[key] || 0;
                  const newCount = parseInt(productData.count, 10);
                  if (oldCount !== newCount) {
                    setUpdatedProducts(prevUpdated => ({
                      ...prevUpdated,
                      [key]: true
                    }));
    
                    setTimeout(() => {
                      setUpdatedProducts(prevUpdated => {
                        const { [key]: _, ...rest } = prevUpdated;
                        return rest;
                      });
                    }, 500);

                    setProductUpdateTimestamps(prevTimestamps => ({
                      ...prevTimestamps,
                      [key]: Date.now()
                    }));

                    setShelfUpdateTimestamps(prevTimestamps => ({
                      ...prevTimestamps,
                      [shelfId]: Date.now()
                    }));

                    if (newCount > oldCount) {
                      shelfUpdated = true;
                    }
                  }
                  return {
                    ...prev,
                    [key]: newCount
                  };
                });
              });

              if (shelfUpdated) {
                setShelfUpdateTimestamps(prevTimestamps => ({
                  ...prevTimestamps,
                  [shelfId]: Date.now()
                }));
              }
            }
          });
        }
      } catch (error) {
        console.error('Error processing inventory data:', error);
      }
    }));


    // Gérer la réponse "adjusted_quantity_response" 
    unsubscribes.push(subscribe('adjusted_quantity_response', (data) => {
      if (data.status === 'success') {
        console.log('[InventorySession] Successfully adjusted quantity:', data.message);
        // Faire eventuellement une notification à l'utilisateur ou mettre à jour un état
      } else {
        console.error('[InventorySession] Failed to adjust quantity:', data.message);
      }
    }));

    // Handle 'update_stock_response'
    unsubscribes.push(subscribe('update_stock_response', (data) => {
      alert('Inventory update sent successfully.');
      setInventoryStarted(false);
      setQuantities({});
      localStorage.removeItem('inventoryQuantities');
      setShelfUpdateTimestamps({});
      setProductUpdateTimestamps({});
      setUpdatedProducts({});
      onBack();
    }));

    // Handle 'update_stock_error'
    unsubscribes.push(subscribe('update_stock_error', (data) => {
      console.error('Error updating inventory:', data.message);
      alert('Error updating inventory: ' + data.message);
    }));

    // Handle 'missing_product_response'
    unsubscribes.push(subscribe('missing_product_response', (data) => {
      setMissingProductResponse({
        type: data.status === 'success' ? 'success' : 'error',
        message: data.message
      });
      
      // Reset after a delay
      setTimeout(() => {
        setMissingProductResponse(null);
      }, 3000);
    }));

    // Handle 'active_zones'
    unsubscribes.push(subscribe('active_zones', (data) => {
      setActiveZones(data.zones);
    }));

    // Handle 'detection_coordinates'
    unsubscribes.push(subscribe('detection_coordinates', (data) => {
      setDetectionCoordinates(data);
    }));

    // Handle 'stream_stats'
    unsubscribes.push(subscribe('stream_stats', (data) => {
      setStreamStats({
        fps: Number(data.data.fps),
        bitrate: Number(data.data.bitrate),
        resolution: {
          width: data.data.resolution?.width || 0,
          height: data.data.resolution?.height || 0
        }
      });
    }));

    return () => {
      unsubscribes.forEach(unsub => unsub());
    };
  }, [socket, subscribe, onBack]);

  // Detect iOS device
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    setIsIOS(/iphone|ipad|ipod/.test(userAgent));
  }, []);

  // Set active session ID
  useEffect(() => {
    if (inventorySessionId) {
      setIsSessionActive(true);
      setLastActiveSessionId(inventorySessionId);
    }
  }, [inventorySessionId, setLastActiveSessionId]);

  // ======================================================
  // MEMOIZED VALUES AND CALLBACKS
  // ======================================================
  /**
   * Memoized sorted shelves calculation
   */
  const sortedShelves = useMemo(() => {
    if (!rack || !rack.shelves) return [];

    // Filter shelves with products having quantities > 0
    const filteredShelves = rack.shelves.filter(shelf => 
      shelf.expected_products.some(product => (quantities[`${shelf.id}-${product.name}`] || 0) > 0)
    );

    const shelvesCopy = [...filteredShelves];

    switch (sortOption) {
      case 'lastUpdate':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelfUpdateTimestamps[shelf.id] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      case 'alphabetical':
        return shelvesCopy.sort((a, b) => a.name.localeCompare(b.name));
      
      case 'objectCount':
        return shelvesCopy
          .map(shelf => ({
            ...shelf,
            sortValue: shelf.expected_products.reduce((sum, p) => {
              const key = `${shelf.id}-${p.name}`;
              return sum + (quantities[key] || 0);
            }, 0)
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      default:
        return shelvesCopy;
    }
  }, [rack, sortOption, shelfUpdateTimestamps, quantities]);

  /**
   * Memoized product sorting function
   */
  const getSortedProducts = useCallback((shelf) => {
    if (!shelf.expected_products) return [];

    // Filter products with quantity > 0
    const filteredProducts = shelf.expected_products.filter(product => 
      (quantities[`${shelf.id}-${product.name}`] || 0) > 0
    );

    const productsCopy = [...filteredProducts];

    switch (sortOption) {
      case 'lastUpdate':
        return productsCopy
          .map(product => ({
            ...product,
            sortValue: productUpdateTimestamps[`${shelf.id}-${product.name}`] || 0
          }))
          .sort((a, b) => b.sortValue - a.sortValue);
      
      case 'alphabetical':
        return productsCopy.sort((a, b) => a.name.localeCompare(b.name));
      
      case 'objectCount':
        return productsCopy.sort((a, b) => {
          const countA = quantities[`${shelf.id}-${a.name}`] || 0;
          const countB = quantities[`${shelf.id}-${b.name}`] || 0;
          return countB - countA;
        });
      
      default:
        return productsCopy;
    }
  }, [sortOption, productUpdateTimestamps, quantities]);

  // ======================================================
  // RENDER
  // ======================================================
  return (
    <div className="min-vh-100 is-flex is-flex-direction-column" style={{ backgroundColor: '#192066' }}>
      {/* Header */}
      <div className="is-flex is-align-items-center py-1 px-2 is-justify-content-space-between has-background-white">
        <div className="is-flex is-align-items-center">
          <button 
            className="button is-small mr-2 is-light" 
            onClick={() => {
              if (inventorySessionId) {
                sendMessage({
                  type: "end_inventory_session",
                  data: { inventory_session_id: inventorySessionId }
                });
              }
              onBack?.();
            }}
          >
            <span className="icon is-small">
              <i className="fas fa-chevron-left"></i>
            </span>
          </button>
          <h1 className="title is-6 mb-0">{rack.name}</h1>
        </div>
        
        <button 
          className="button is-small is-light"
          onClick={() => setShowStats(!showStats)}
        >
          <span className="icon">
            <i className="fas fa-info-circle"></i>
          </span>
        </button>
      </div>
  
      {/* Stats Panel */}
      <div className={`px-2 ${!showStats && 'is-hidden'}`}>
        <StatsPanel stats={streamStats} smoothedLatency={smoothedLatency} />
      </div>
  
      {/* Main Content */}
      <div className="is-flex is-flex-direction-column" style={{ flex: 1, width: '100%' }}>
        {/* Video Container with Overlay Controls */}
        <div>
          <div className="box has-background-white p-2 mb-2" style={{ 
            marginLeft: 0,
            marginRight: 0,
            borderRadius: '0 0 12px 12px',
            borderTop: 'none'
          }}>
            <div 
              className="has-background-black" 
              style={{ 
                borderRadius: '0 0 8px 8px',
                overflow: 'hidden',
                position: 'relative',
                paddingTop: '100%'
              }}
            >
              <div 
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
              >
                {shouldShowVideo && (
                  <VideoOverlay
                    websocket={socket}
                    inventorySessionId={inventorySessionId}
                    mode={mode}
                    onVideoReady={() => {}}
                    detectionData={detectionCoordinates}
                    userContext={userContext}
                    onStatsUpdate={handleStatsUpdate}
                  />
                )}
              </div>
  
              {/* Overlay Controls */}
              <div 
                className="is-flex is-justify-content-center" 
                style={{
                  position: 'absolute',
                  bottom: '10px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 10,
                  width: '90%'
                }}
              >
                <div 
                  className="buttons is-centered has-addons" 
                  style={{
                    borderRadius: '20px',
                    padding: '0.3rem',
                    margin: 0
                  }}
                >
                  <button 
                    className={`button ${sortOption === 'alphabetical' ? 'is-info' : 'is-light'}`} 
                    onClick={() => setSortOption('alphabetical')}
                  >
                    <span className="icon">
                      <i className="fas fa-sort-alpha-down"></i>
                    </span>
                  </button>
                  
                  <button 
                    className={`button ${sortOption === 'objectCount' ? 'is-info' : 'is-light'}`} 
                    onClick={() => setSortOption('objectCount')}
                  >
                    <span className="icon">
                      <i className="fas fa-sort-numeric-down"></i>
                    </span>
                  </button>
  
                  <button 
                    className={`button ${sortOption === 'lastUpdate' ? 'is-info' : 'is-light'}`} 
                    onClick={() => setSortOption('lastUpdate')}
                  >
                    <span className="icon">
                      <i className="fas fa-clock"></i>
                    </span>
                  </button>

  
  
                  <button
                    onClick={!inventoryStarted ? handleStartInventory : handleStopInventory}
                    className={`button ${!inventoryStarted ? 'is-success' : 'is-danger'}`}
                  >
                    <span className="icon">
                      <i className={`fas ${!inventoryStarted ? 'fa-play' : 'fa-stop'}`}></i>
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Inventory Content */}
        <div className="px-2 pb-4" style={{ overflowY: 'auto' }}>
          {Object.keys(quantities).length === 0 && !isVideoActive ? (
            <>
              <div className="notification is-info is-light has-text-centered">
                <p>Ready to start your inventory? <strong>Start the session</strong> to begin detecting products! 🪄</p>
              </div>
              <div className="mt-4">
                <RackInfoDisplay 
                  userContext={userContext} 
                  rack={rack} 
                  className="box"
                />
              </div>
            </>
          ) : (
            <div className="shelves-container">
              {sortedShelves.length === 0 ? (
                <div className="notification is-warning has-text-centered">
                  <p>No products detected yet. Keep the session running!</p>
                </div>
              ) : (
                <>
                  {sortedShelves.map(shelf => (
                    <div key={shelf.id} className="box mb-2">
                      <h3 className="is-flex is-align-items-center is-justify-content-space-between mb-2">
                        <span className="has-text-weight-semibold">{shelf.name}</span>
                        {renderShelfMissingButton(shelf)}
                      </h3>
                      
                      {getSortedProducts(shelf).map(product => {
                        const key = `${shelf.id}-${product.name}`;
                        return (
                          <div key={product.id} className="field has-addons mb-2">
                            <div className="control is-expanded has-text-left">
                              <p className="is-size-6 has-text-weight-semibold mb-1">
                                {truncate(product.description || product.name, 50)}
                              </p>
                              <p className="is-size-7 has-text-secondary">{product.name}</p>
                            </div>
                            <div className="control">
                              <button 
                                className="button is-primary"
                                style={{ height: '2.5rem' }}
                                onClick={() => handleQuantityChange(shelf.id, product.name, -1)}
                              >
                                <span className="icon">
                                  <i className="fas fa-minus"></i>
                                </span>
                              </button>
                            </div>
                            <div className="control">
                              <input 
                                className={`input has-text-centered count-cell no-spin ${updatedProducts[key] ? 'is-blinking' : ''}`}
                                style={{ width: '4rem', height: '2.5rem' }}
                                type="number"
                                value={quantities[key] || 0}
                                onChange={(e) => handleQuantityChange(
                                  shelf.id, 
                                  product.name, 
                                  parseInt(e.target.value, 10) - (quantities[key] || 0)
                                )}
                              />
                            </div>
                            <div className="control">
                              <button 
                                className="button is-primary"
                                style={{ height: '2.5rem' }}
                                onClick={() => handleQuantityChange(shelf.id, product.name, 1)}
                              >
                                <span className="icon">
                                  <i className="fas fa-plus"></i>
                                </span>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
  
                  {sortedShelves.length > 0 && (
                    <button 
                      className="button is-success is-fullwidth mt-4 mb-4"
                      onClick={sendInventoryUpdate}
                      style={{ height: '2.75rem' }}
                    >
                      <span className="icon"><i className="fas fa-paper-plane"></i></span>
                      <span>Send Inventory</span>
                    </button>
                  )}
                </>
              )}
            </div>
          )}
        </div>

        console.debug('userContext dans InventorySession:', userContext);
  
        {/* Missing Product Modal */}
        <MissingProductModal
          isOpen={showMissingProductModal}
          onClose={() => setShowMissingProductModal(false)}
          shelves={rack.shelves}
          selectedShelfId={selectedShelfForMissing}
          onSubmit={handleMissingProductSubmit}
          userContext={userContext}
          canSubmit={inventoryStarted || lastActiveSessionId}
          submissionResponse={missingProductResponse}
        />
      </div>
    </div>
  );

};

export default InventorySession;
