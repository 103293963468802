// VideoOverlay.js
import React, { useState, useEffect, useRef, useCallback } from 'react';
import SimplePeer from 'simple-peer';
import { useWebSocket } from '../contexts/WebSocketContext';
import { LOCAL_VIDEO_CONSTRAINTS, SERVER_VIDEO_CONSTRAINTS } from '../config/videoConfig';

const FADE_DURATION = 500; // Durée du fade en ms

const VideoOverlay = ({ 
  websocket, 
  inventorySessionId,
  mode = 'tagzone',
  onVideoReady, 
  userContext,
  onStatsUpdate,
  selectedProduct,
  isSelectionLocked
}) => {
  // console.log('[VideoOverlay] Initializing with props:', {
  //   hasWebsocket: !!websocket,
  //   inventorySessionId,
  //   mode,
  //   hasUserContext: !!userContext
  // });

  // console.log('[VideoOverlay] Mode:', mode);

  // Refs
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const streamRef = useRef(null);
  const peerRef = useRef(null);
  const lastDetectionsRef = useRef({ zones: [], boxes: [] });

  // États
  const [isLoading, setIsLoading] = useState(true);
  const [videoReady, setVideoReady] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });
  const [currentDetections, setCurrentDetections] = useState({
    zones: [],
    boxes: []
  });
  const [fadingElements, setFadingElements] = useState({
    zones: [],
    boxes: []
  });

  // État pour suivre l'initialisation
  const [isInitialized, setIsInitialized] = useState(false);

  // Mode d'opération
  const [operationMode, setOperationMode] = useState(mode);

  const [countingDetections, setCountingDetections] = useState([]);

  // Détection de la dernière mise à jour pour le fade-out
  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());

  // Hook WebSocket personnalisé
  const { subscribe, sendMessage } = useWebSocket();

  // Données de la ligne de balayage
  const [sweepLineData, setSweepLineData] = useState(null);

  // Gestion du fade-out
  const handleFadeOut = useCallback((type, newElements) => {
    const currentIds = new Set(newElements.map(e => e.id));
    const previousElements = lastDetectionsRef.current[type];
    
    // Identifier les éléments qui ont disparu
    const disappearingElements = previousElements.filter(elem => !currentIds.has(elem.id));
    
    if (disappearingElements.length > 0) {
      setFadingElements(prev => ({
        ...prev,
        [type]: [
          ...prev[type],
          ...disappearingElements.map(elem => ({
            ...elem,
            startFade: Date.now()
          }))
        ]
      }));
    }
    
    // Mettre à jour la référence
    lastDetectionsRef.current[type] = newElements;
  }, []);

  // Configuration WebRTC
  const initializePeerConnection = useCallback(async () => {
    console.log('[VideoOverlay] Starting peer connection initialization');
    try {

      if (peerRef.current) {
        console.log('[VideoOverlay] Cleaning up existing peer connection');
        peerRef.current.destroy();
      }

      // Obtenir le stream du serveur
      console.log('[VideoOverlay] Requesting server stream...');
      const serverStream = await navigator.mediaDevices.getUserMedia({
        video: SERVER_VIDEO_CONSTRAINTS,
        audio: false
      });
      console.log('[VideoOverlay] Server stream obtained:', serverStream.getVideoTracks()[0].getSettings());

      // Obtenir le stream local
      console.log('[VideoOverlay] Requesting local stream...');
      const localStream = await navigator.mediaDevices.getUserMedia({
        video: LOCAL_VIDEO_CONSTRAINTS,
        audio: false
      });
      console.log('[VideoOverlay] Local stream obtained:', localStream.getVideoTracks()[0].getSettings());

      if (!videoRef.current) {
        console.error('[VideoOverlay] Video element not found');
        return;
      }

      videoRef.current.srcObject = localStream;
      streamRef.current = localStream;

      console.log('[VideoOverlay] Creating peer connection');
      const peer = new SimplePeer({
        initiator: true,
        trickle: false,
        stream: serverStream,
        config: { 
          iceServers: [
            { urls: 'stun:stun.l.google.com:19302' },
            { urls: 'stun:stun1.l.google.com:19302' }
          ] 
        }
      });

      peerRef.current = peer;

      return new Promise((resolve, reject) => {
        peer.on('signal', data => {
          console.log('[VideoOverlay] Peer signaling:', data.type);
          if (data.type === 'offer') {
            sendMessage({
              type: 'offer',
              sdp: data.sdp,
              inventory_session_id: inventorySessionId,
              user_context: userContext
            });
          }
        });

        peer.on('connect', () => {
          console.log('[VideoOverlay] Peer connected');
          setIsConnected(true);
          resolve(peer);
        });

        peer.on('error', err => {
          console.error('[VideoOverlay] Peer error:', err);
          setIsLoading(false);
          reject(err);
        });

        peer._pc.addEventListener('iceconnectionstatechange', () => {
          console.log('[VideoOverlay] ICE state:', peer._pc.iceConnectionState);
          if (['connected', 'completed'].includes(peer._pc.iceConnectionState)) {
            setIsLoading(false);
            setVideoReady(true);
          }
        });
      });
    } catch (error) {
      console.error('[VideoOverlay] Error in initialization:', error);
      setIsLoading(false);
      throw error;
    }
  }, [inventorySessionId, sendMessage, userContext]);

  const drawArrow = useCallback((x, y, direction) => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;
  
    ctx.save();
    ctx.translate(x, y);
  
    // Définir la taille de la flèche
    const arrowSize = Math.min(canvasRef.current.width, canvasRef.current.height) * 0.03;
  
    // Rotation selon la direction
    let rotation = 0;
    switch (direction) {
      case 'down_to_up':
        rotation = 0;
        break;
      case 'up_to_down':
        rotation = Math.PI;
        break;
      case 'left_to_right':
        rotation = Math.PI / 2;
        break;
      case 'right_to_left':
        rotation = -Math.PI / 2;
        break;
      default:
        rotation = 0;
    }
  
    ctx.rotate(rotation);
  
    // Dessiner la flèche
    ctx.beginPath();
    ctx.moveTo(-arrowSize / 2, arrowSize / 2);
    ctx.lineTo(0, -arrowSize / 2);  // Pointe de la flèche
    ctx.lineTo(arrowSize / 2, arrowSize / 2);
    ctx.closePath();
  
    // Appliquer le style
    ctx.fillStyle = 'rgba(255, 0, 0, 0.8)';
    ctx.fill();
  
    ctx.restore();
  }, [canvasRef]);

  // Nettoyage des éléments en fade
  useEffect(() => {
    const cleanupInterval = setInterval(() => {
      const now = Date.now();
      setFadingElements(prev => {
        const newZones = prev.zones.filter(zone => now - zone.startFade < FADE_DURATION);
        const newBoxes = prev.boxes.filter(box => now - box.startFade < FADE_DURATION);
  
        return { zones: newZones, boxes: newBoxes };
      });
  
      // Vérifier s'il y a un délai sans nouvelles données
      // Si plus de X ms sans update et que currentDetections n'est pas vide, on déclenche fadeOut
      const NO_UPDATE_TIMEOUT = 1000; // Par exemple 1 secondes sans données
      if (now - lastUpdateTime > NO_UPDATE_TIMEOUT) {
        // Pas de nouvelle détection depuis un moment, tout doit disparaître
        if (currentDetections.zones.length > 0) {
          handleFadeOut('zones', []); 
          setCurrentDetections(prev => ({ ...prev, zones: [] }));
        }
        if (currentDetections.boxes.length > 0) {
          handleFadeOut('boxes', []);
          setCurrentDetections(prev => ({ ...prev, boxes: [] }));
        }
      }
    }, 100);
  
    return () => clearInterval(cleanupInterval);
  }, [currentDetections, lastUpdateTime, handleFadeOut]);

  // useEffect pour tracer les changements de mode
  useEffect(() => {
    console.log('[VideoOverlay] Mode changed to:', mode);
  }, [mode]);

  useEffect(() => {
    // Mettre à jour le mode si changé
    console.log('[VideoOverlay] Current mode:', mode);
    setOperationMode(mode);
  }, [mode]);

  // Souscription aux messages WebSocket
  useEffect(() => {
    if (!websocket) {
      console.log('[VideoOverlay] No WebSocket connection available');
      return;
    }

    console.log('[VideoOverlay] Setting up WebSocket subscriptions');
    
    const unsubscribes = [

      // Gestion de la réponse SDP
      subscribe('answer', async (data) => {
        console.log('[VideoOverlay] Received answer from server:', {
          hasPeer: !!peerRef.current,
          peerState: peerRef.current?._pc.signalingState,
          isConnected
        });
        
        try {
          if (peerRef.current && !isConnected && 
              peerRef.current._pc.signalingState !== 'stable') {
            console.log('[VideoOverlay] Processing answer');
            await peerRef.current.signal(data);
          } else {
            console.log('[VideoOverlay] Skipping answer - peer not ready or already connected');
          }
        } catch (error) {
          console.error('[VideoOverlay] Error handling answer:', error);
        }

      }),
      
      // Gestion des zones actives
      subscribe('active_zones', (data) => {
        console.log("📥 WEBSOCKET RAW DATA:", {
          fullData: data,
          type: data?.type,
          hasZones: !!data?.zones,
          zonesType: typeof data?.zones,
          firstZone: data?.zones?.[0],
          stack: new Error().stack  // Pour voir d'où vient l'appel
        });

        console.log("📥 WEBSOCKET - active_zones received:", data);

        if (!data || !data.zones || !Array.isArray(data.zones)) {
          console.warn('Invalid active_zones message:', data);
          return;
        }

        if (data.image_dimensions) {
          setImageDimensions(data.image_dimensions);
        }

        if (operationMode === 'tagzone') {
          // Debug: examiner chaque zone avant transformation
          data.zones.forEach(zone => {
            console.log("🔎 Active Zones - Processing zone:", {
              originalZone: zone,
              hasBbox: !!zone.bbox,
              bboxType: typeof zone.bbox,
              bboxContent: zone.bbox
            });
          });
          // Transformer les zones en préservant toutes les informations
          const transformedZones = data.zones.map(zone => {
            const transformed = {
              id: zone.shelf_id,
              name: zone.shelf_name,
              rack_id: zone.rack_id,
              shelf_id: zone.shelf_id,
              bbox: (Array.isArray(zone.bbox) ? [...zone.bbox] : []), // Important : faire une copie du tableau bbox
              status: zone.status,
              type: 'zone'
            };
            console.log("🔄 Zone transformation:", {
              original: zone,
              transformed: transformed
            });
            return transformed;
          });

          console.log("✅ Active Zones - Final transformed zones:", transformedZones);

          console.log("✅ Setting currentDetections with zones:", transformedZones);
          setCurrentDetections(prev => {
            console.log("🔄 State update:", {
              previous: prev,
              newZones: transformedZones
            });
            return {
              ...prev,
              zones: transformedZones
            };
          });
        }
        
        setLastUpdateTime(Date.now());
      }),

      // Gestion des détections d'objets
      subscribe('detection_coordinates', (data) => {
        // console.log("[VideoOverlay] Raw detection data:", data);
        if (data.image_dimensions) {
          setImageDimensions(data.image_dimensions);
        }
      
        if (operationMode === 'counting') {
          // Conserver la logique existante pour le mode counting
          const detections = data.data?.detections || [];
          if (detections.length > 0) {
            const transformedDetections = detections.map(box => {
              let x, y, width, height;
              if (Array.isArray(box.bbox)) {
                const [x1, y1, x2, y2] = box.bbox;
                x = x1;
                y = y1;
                width = x2 - x1;
                height = y2 - y1;
              } else {
                x = box.x || box.bbox?.x || 0;
                y = box.y || box.bbox?.y || 0;
                width = box.width || box.bbox?.width || 0;
                height = box.height || box.bbox?.height || 0;
              }
      
              return {
                ...box,
                id: box.id || `detection-${Date.now()}-${Math.random()}`,
                x,
                y,
                width,
                height,
                class_name: box.class_name || box.className || "Unknown",
                confidence: box.confidence || 1.0,
                type: 'counting_box'
              };
            });
      
            setCountingDetections(transformedDetections);
          } else {
            setCountingDetections([]);
          }
        } else if (operationMode === 'tagzone') {
          // Mode tagzone : utilisation directe des bounding_boxes
          if (data.bounding_boxes && Array.isArray(data.bounding_boxes)) {
            console.log("📍 DETECTION - Processing bounding boxes:", data.bounding_boxes);
      
            // Mise à jour de l'état des détections
            setCurrentDetections(prev => {
              const newState = {
                ...prev,
                boxes: data.bounding_boxes
              };
              // console.log("📍 DETECTION - Updated state:", newState);
              return newState;
            });
      
            // Mise à jour des éléments en fade
            handleFadeOut('boxes', data.bounding_boxes);
          } else {
            // console.log("📍 DETECTION - No bounding boxes received");
            setCurrentDetections(prev => ({
              ...prev,
              boxes: []
            }));
          }
        }

        setLastUpdateTime(Date.now());
      }),

      // Gestion des stats du stream
      subscribe('stream_stats', (data) => {
        // console.log("[VideoOverlay] Received stream stats:", data);
        if (data.data && onStatsUpdate) {
          try {
            const stats = {
              fps: Number(data.data.fps),
              bitrate: Number(data.data.bitrate),
              resolution: {
                width: data.data.resolution?.width || 0,
                height: data.data.resolution?.height || 0
              }
            };
            onStatsUpdate(stats);
          } catch (error) {
            console.error('[VideoOverlay] Error processing stream stats:', error);
          }
        }
      }),

      // Gestionnaire pour la fin de session
      subscribe('counting_session_ended', () => {
        // console.log('[VideoOverlay] Counting session ended, cleaning up...');
        
        // Nettoyer les détections
        setCountingDetections([]);
        setCurrentDetections({ zones: [], boxes: [] });
        setFadingElements({ zones: [], boxes: [] });
        
        // Réinitialiser les dimensions
        setImageDimensions({});
        
        // Arrêter la vidéo si nécessaire
        if (streamRef.current) {
          streamRef.current.getTracks().forEach(track => track.stop());
        }
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
      }),

      subscribe('sweep_update', (data) => {
        // Log pour debug
        // console.log("[VideoOverlay] Received sweep update:", {
        //   line_coords: data.data?.line_coords,
        //   canvas: {
        //     width: canvasRef.current?.width,
        //     height: canvasRef.current?.height
        //   }
        // });

        if (data.data?.line_coords) {
          setSweepLineData({
            ...data.data,
            line_coords: {
              start: {
                x: data.data.line_coords.start.x,
                y: data.data.line_coords.start.y
              },
              end: {
                x: data.data.line_coords.end.x,
                y: data.data.line_coords.end.y
              }
            }
          });
        }

      })

    ];

    return () => {
      // console.log('[VideoOverlay] Cleaning up WebSocket subscriptions');
      unsubscribes.forEach(unsub => unsub());
    };
  }, [websocket, subscribe, isConnected, mode]);

  // Initialisation de la connexion vidéo
  useEffect(() => {
    // console.log('[VideoOverlay] Video initialization effect triggered:', {
    //   hasInventorySessionId: !!inventorySessionId,
    //   hasWebsocket: !!websocket,
    //   isInitialized,
    //   currentMode: mode,
    //   isConnected,
    //   websocketState: websocket?.readyState
    // });

    let mounted = true;

    if (inventorySessionId && websocket && !isInitialized && websocket.readyState === WebSocket.OPEN) {
      // console.log('[VideoOverlay] Conditions met, starting initialization');
      setIsInitialized(true);
      initializePeerConnection()
        .then(() => {
          if (mounted) {
            console.log('[VideoOverlay] Peer connection success');
          } else {
            console.log('[VideoOverlay] Peer connection success but not mounted');
          }
        })
        .catch(error => {
          console.error('[VideoOverlay] Peer connection failed:', error);
          setIsInitialized(false);
        });
    } else {
      console.log('[VideoOverlay] Waiting for conditions:', {
        hasId: !!inventorySessionId,
        hasSocket: !!websocket,
        notInitialized: !isInitialized
      });
    }

    return () => {
      mounted = false;
      if (isInitialized) {
        console.log('[VideoOverlay] Cleaning up video resources');
        cleanupVideoResources();
        try {
          if (streamRef.current) {
            console.log('[VideoOverlay] Stopping video tracks');
            streamRef.current.getTracks().forEach(track => {
              try {
                track.stop();
              } catch (e) {
                console.warn('[VideoOverlay] Error stopping track:', e);
              }
            });
          }
          if (peerRef.current) {
            console.log('[VideoOverlay] Destroying peer connection');
            try {
              if (typeof peerRef.current.destroy === 'function') {
                peerRef.current.destroy();
              } else if (typeof peerRef.current.close === 'function') {
                peerRef.current.close();
              }
            } catch (e) {
              console.warn('[VideoOverlay] Error destroying peer connection:', e);
            }
          }
        } catch (error) {
          console.error('[VideoOverlay] Error during resource cleanup:', error);
        }
      }
    };
  }, [inventorySessionId, websocket, isInitialized, websocket?.readyState]);

  // Ajouter les gestionnaires d'événements vidéo
  useEffect(() => {
    const videoElement = videoRef.current;
    if (!videoElement) return;
  
    const handleLoadedMetadata = () => {
      const videoWidth = videoElement.videoWidth;
      const videoHeight = videoElement.videoHeight;
      
      // console.log('[VideoOverlay] Video dimensions changed:', {
      //   width: videoWidth,
      //   height: videoHeight
      // });
      
      if (videoWidth && videoHeight) {
        setImageDimensions({
          width: videoWidth,
          height: videoHeight
        });
      }
    };
  
    const handleCanPlay = () => {
      setIsLoading(false);
      if (onVideoReady) {
        onVideoReady();
      }
    };
  
    // Handler pour les changements de dimensions en cours de streaming
    const handleResize = () => {
      if (videoElement.videoWidth && videoElement.videoHeight) {
        console.log('[VideoOverlay] Video resized:', {
          width: videoElement.videoWidth,
          height: videoElement.videoHeight
        });
        
        setImageDimensions({
          width: videoElement.videoWidth,
          height: videoElement.videoHeight
        });
      }
    };
  
    videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
    videoElement.addEventListener('canplay', handleCanPlay);
    videoElement.addEventListener('resize', handleResize);
  
    // Si la vidéo est déjà chargée
    if (videoElement.videoWidth && videoElement.videoHeight) {
      handleLoadedMetadata();
    }
  
    return () => {
      videoElement.removeEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.removeEventListener('canplay', handleCanPlay);
      videoElement.removeEventListener('resize', handleResize);
    };
  }, [onVideoReady]);

  const cleanupVideoResources = useCallback(() => {
    try {
        if (streamRef.current) {
            console.log('[VideoOverlay] Stopping video tracks');
            streamRef.current.getTracks().forEach(track => {
                try {
                    track.stop();
                } catch (e) {
                    console.warn('[VideoOverlay] Error stopping track:', e);
                }
            });
            streamRef.current = null;
        }
        if (peerRef.current) {
            console.log('[VideoOverlay] Destroying peer connection');
            try {
                peerRef.current.destroy();
                peerRef.current = null;
            } catch (e) {
                console.warn('[VideoOverlay] Error destroying peer connection:', e);
            }
        }
        setIsInitialized(false);
        setVideoReady(false);
    } catch (error) {
        console.error('[VideoOverlay] Error during resource cleanup:', error);
    }
  }, []);

  const drawElement = useCallback((ctx, element, type, baseOpacity = 0.8) => {
    // console.log("🎯 DrawElement - Called with:", { type, element, baseOpacity });

    if (!ctx || !element) {
      console.warn('[VideoOverlay] Missing context or element');
      return;
    }
  
    const canvas = canvasRef.current;
    if (!canvas) {
      console.warn('[VideoOverlay] Canvas not found');
      return;
    }
  
    if (!imageDimensions.width || !imageDimensions.height) {
      console.warn('[VideoOverlay] Missing image dimensions');
      return;
    }
  
    // Calculer les facteurs d'échelle
    const scaleX = canvas.width / imageDimensions.width;
    const scaleY = canvas.height / imageDimensions.height;
  
    // Fonction utilitaire pour transformer les coordonnées
    const transformCoords = (x, y) => ({
      x: x * scaleX,
      y: y * scaleY
    });
  
    // Fonction utilitaire pour transformer les dimensions
    const transformDimensions = (width, height) => ({
      width: width * scaleX,
      height: height * scaleY
    });
  
    // Gestion du fade-out
    let opacity = baseOpacity;
    if (element.startFade) {
      const fadeProgress = (Date.now() - element.startFade) / FADE_DURATION;
      opacity = baseOpacity * (1 - fadeProgress);
    }
  
    // Variables communes pour le texte
    const getFontSize = () => Math.max(12, Math.min(14, canvas.width / 40));
  
    // Configuration du texte
    const setupText = (fontSize) => {
      ctx.font = `${fontSize}px Arial`;
      ctx.textAlign = 'center';
    };
  
    // Fonction pour dessiner le fond du texte
    const drawTextBackground = (text, x, y, fontSize) => {
      const textMetrics = ctx.measureText(text);
      const padding = 3;
      ctx.fillStyle = `rgba(0, 0, 0, ${opacity * 0.7})`;
      ctx.fillRect(
        x - (textMetrics.width / 2) - padding,
        y - fontSize - padding,
        textMetrics.width + (padding * 2),
        fontSize + (padding * 2)
      );
    };
  
    let x, y, width, height, transformedCoords, transformedDims;
  
    switch (type) {
      case 'counting_box': {
        // Les coordonnées sont déjà relatives (0-1)
        const bbox = element.bbox;
        
        // Conversion directe en pixels du canvas
        const x = bbox[0] * canvas.width;
        const y = bbox[1] * canvas.height;
        const width = (bbox[2] - bbox[0]) * canvas.width;
        const height = (bbox[3] - bbox[1]) * canvas.height;
    
        let baseColor;
        if (!isSelectionLocked) {
          baseColor = [255, 255, 255]; // Blanc quand pas verrouillé
        } else {
          const isSelectedProduct = selectedProduct && 
            element.class_name.toLowerCase() === selectedProduct.name.toLowerCase();
          baseColor = isSelectedProduct ? [0, 255, 0] : [255, 0, 0];
        }
    
        // Dessin du rectangle
        ctx.strokeStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, width, height);
    
        // Ajout du label
        if (element.class_name) {
          const fontSize = getFontSize();
          setupText(fontSize);
          const labelText = `${element.class_name} ${
            element.confidence ? `(${Math.round(element.confidence * 100)}%)` : ''
          }`;
          const labelX = x + width / 2;
          const labelY = y + height - 5;
    
          drawTextBackground(labelText, labelX, labelY, fontSize);
          ctx.fillStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
          ctx.fillText(labelText, labelX, labelY);
        }
        break;
      }
      case 'sweep_box': {

        // Les coordonnées sont déjà relatives (0-1)
        let x, y, width, height;
        
        if (Array.isArray(element.bbox)) {
          // Format [x1, y1, x2, y2]
          const [x1, y1, x2, y2] = element.bbox;
          x = x1 * canvas.width;
          y = y1 * canvas.height;
          width = (x2 - x1) * canvas.width;
          height = (y2 - y1) * canvas.height;
        } else {
          // Format {x, y, width, height}
          x = element.x * canvas.width;
          y = element.y * canvas.height;
          width = element.width * canvas.width;
          height = element.height * canvas.height;
        }

        // Détermination de la couleur selon l'état de la boîte
        let baseColor;
        if (!isSelectionLocked) {
          baseColor = [128, 128, 128]; // Gris si non verrouillé
        } else {
          if (element.class_name === selectedProduct?.name) {
            // Produit sélectionné
            if (element.crossed_line) {
              // Si l'objet a traversé la ligne
              if (element.valid_count) {
                baseColor = [0, 255, 0];  // Vert si compté dans le bon sens
              } else {
                baseColor = [255, 165, 0]; // Orange si compté dans le mauvais sens
              }
            } else {
              baseColor = [0, 255, 0];  // Vert si pas encore traversé
            }
          } else {
            baseColor = [255, 0, 0];  // Rouge pour les autres produits
          }
        }

        // Dessin du rectangle
        ctx.strokeStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
        ctx.lineWidth = 2;
        ctx.strokeRect(x, y, width, height);

        // Ajout du label avec ID de tracking et confiance
        if (element.class_name) {
          const fontSize = getFontSize();
          setupText(fontSize);

          // Construction du texte du label
          let labelText = element.class_name;
          if (element.track_id) {
            labelText += ` (#${element.track_id})`;
          }
          if (element.confidence) {
            labelText += ` (${Math.round(element.confidence * 100)}%)`;
          }

          const labelX = x + width / 2;
          const labelY = y + height - 5;

          // Fond du texte
          drawTextBackground(labelText, labelX, labelY, fontSize);
          
          // Texte principal
          ctx.fillStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
          ctx.fillText(labelText, labelX, labelY);

          // Statut de traversée si applicable
          if (element.crossed_line) {
            const statusText = element.valid_count ? "Compté ✓" : "Mauvais sens ✗";
            const statusY = labelY - fontSize - 5;
            
            drawTextBackground(statusText, labelX, statusY, fontSize);
            ctx.fillText(statusText, labelX, statusY);
          }
        }
        break;
      }
  
      case 'zone': {

        // Vérification de la structure du bbox
        if (!element.bbox || !Array.isArray(element.bbox) || element.bbox.length !== 4) {
          return;
        }

        // Coordonnées relatives -> pixels canvas
        const [x1, y1, x2, y2] = element.bbox.map((coord, i) => {
          const scaled = i % 2 === 0 ? coord * canvas.width : coord * canvas.height;
          return scaled;
        });

        const width = x2 - x1;
        const height = y2 - y1;

        // Style pour les zones
        ctx.strokeStyle = `rgba(255, 255, 255, ${opacity})`;
        ctx.lineWidth = 3;
        ctx.strokeRect(x1, y1, width, height);

        // Label pour la zone
        if (element.name) {
          const fontSize = getFontSize();
          setupText(fontSize);
          const labelText = element.name;
          const labelX = x1 + width / 2;
          // Déplacer le label en bas de la zone avec un petit padding
          const labelY = y1 + height - fontSize - 5;  // 5px de padding

          drawTextBackground(labelText, labelX, labelY, fontSize);
          ctx.fillStyle = `rgba(255, 255, 255, ${opacity})`;
          ctx.fillText(labelText, labelX, labelY);
        }
        break;
      }

      case 'box': {
        // Coordonnées relatives -> pixels canvas
        const [x1, y1, x2, y2] = element.bbox.map((coord, i) => 
          i % 2 === 0 ? coord * canvas.width : coord * canvas.height
        );
        const width = x2 - x1;
        const height = y2 - y1;

        // Couleur selon si l'objet est attendu dans la zone
        const baseColor = element.expected_in_zone ? [0, 255, 0] : [255, 0, 0];
        
        ctx.strokeStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
        ctx.lineWidth = 2;
        ctx.strokeRect(x1, y1, width, height);

        // Label pour la box
        if (element.class_name) {
          const fontSize = getFontSize();
          setupText(fontSize);
          const labelText = `${element.expected_in_zone ? '' : '⚠️ '}${element.class_name}`;
          const labelX = x1 + width / 2;
          const labelY = y1 + height - 3;

          drawTextBackground(labelText, labelX, labelY, fontSize);
          ctx.fillStyle = `rgba(${baseColor[0]}, ${baseColor[1]}, ${baseColor[2]}, ${opacity})`;
          ctx.fillText(labelText, labelX, labelY);
        }
        break;
      }
  
      case 'sweep_line': {
        // Vérification des données de sweep line
        if (!sweepLineData) {
            console.warn('[VideoOverlay] Missing sweep line data');
            return;
        }

        // Style de base pour la ligne
        ctx.strokeStyle = `rgba(255, 0, 0, ${opacity})`;
        ctx.lineWidth = 5;

        // Convertir les coordonnées relatives en pixels canvas
        const start = {
          x: element.start.x * canvas.width,
          y: element.start.y * canvas.height
        };
        const end = {
          x: element.end.x * canvas.width,
          y: element.end.y * canvas.height
        };
    
        // Dessin de la ligne principale
        ctx.beginPath();
        ctx.moveTo(start.x, start.y);
        ctx.lineTo(end.x, end.y);
        ctx.stroke();

      }
      
    }
  }, [imageDimensions, isSelectionLocked, selectedProduct, sweepLineData, canvasRef]);

  // Mettre à jour les dimensions du canvas en fonction de la taille de la vidéo
  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    
    const updateDimensions = () => {
      if (!canvas || !video) return;
      
      const videoRect = video.getBoundingClientRect();
      canvas.width = videoRect.width;
      canvas.height = videoRect.height;

    };

    const resizeObserver = new ResizeObserver(updateDimensions);
    if (video) {
      resizeObserver.observe(video);
    }

    return () => {
      if (video) {
        resizeObserver.unobserve(video);
      }
    };
  }, []);

  // Rendu du canvas
  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (!canvas || !video || !videoReady) return;
  
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
  
    const draw = () => {
      if (!canvasRef.current || !videoRef.current) return;
  
      const videoRect = videoRef.current.getBoundingClientRect();
      
      if (canvas.width !== videoRect.width || canvas.height !== videoRect.height) {
        canvas.width = videoRect.width;
        canvas.height = videoRect.height;
      }
  
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      switch (operationMode) {
        case 'tagzone': {
          // console.log("🎨 Render - Current Detections:", currentDetections);

          // Dessiner d'abord les zones
          currentDetections.zones?.forEach(zone => {
            // console.log("🖼️ Render - Drawing zone:", zone);
            drawElement(ctx, zone, 'zone');
          });
        
          // Ensuite dessiner les boxes
          currentDetections.boxes?.forEach(box => {
            drawElement(ctx, box, 'box');
          });
          break;
        }
        case 'counting': {
          countingDetections.forEach(detection => {
            drawElement(ctx, detection, 'counting_box');
          });
          break;
        }
        case 'sweep': {
          if (sweepLineData?.line_coords) {
            drawElement(ctx, sweepLineData.line_coords, 'sweep_line');
          }
          if (sweepLineData?.detections) {
            Object.values(sweepLineData.detections).forEach(detection => {
              drawElement(ctx, detection, 'sweep_box');
            });
          }
          break;
        }
      }
  
      requestAnimationFrame(draw);
    };
  
    const animationId = requestAnimationFrame(draw);
    return () => cancelAnimationFrame(animationId);
  }, [
    currentDetections,
    countingDetections,
    sweepLineData,
    imageDimensions,
    videoReady,
    operationMode,
    drawElement
  ]);

  // Améliorer le cleanup
  useEffect(() => {
    return () => {
      console.log('[VideoOverlay] Component cleanup');
      try {
        // Arrêter les tracks vidéo
        if (streamRef.current) {
          console.log('[VideoOverlay] Stopping video tracks');
          streamRef.current.getTracks().forEach(track => {
            try {
              track.stop();
            } catch (e) {
              console.warn('[VideoOverlay] Error stopping track:', e);
            }
          });
        }

        // Nettoyer la connexion peer
        if (peerRef.current) {
          console.log('[VideoOverlay] Cleaning up peer connection');
          try {
            if (typeof peerRef.current.destroy === 'function') {
              peerRef.current.destroy();
            } else if (typeof peerRef.current.close === 'function') {
              peerRef.current.close();
            }
          } catch (e) {
            console.warn('[VideoOverlay] Error cleaning up peer connection:', e);
          }
        }

        // Réinitialiser les états
        setVideoReady(false);
        setIsLoading(true);
        setIsInitialized(false);
        setImageDimensions({});
        setCountingDetections([]);
        setCurrentDetections({ zones: [], boxes: [] });
        setFadingElements({ zones: [], boxes: [] });
      } catch (error) {
        console.error('[VideoOverlay] Error during cleanup:', error);
      }
    };
  }, []);

  // Mettre à jour les dimensions du canvas en fonction de la taille du conteneur
  useEffect(() => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    
    const updateCanvasDimensions = () => {
      if (!canvas || !video) return;
      
      const container = canvas.parentElement;
      const containerRect = container.getBoundingClientRect();
      
      canvas.width = containerRect.width;
      canvas.height = containerRect.height;
      
      console.log('[VideoOverlay] Canvas dimensions set to:', {
        width: canvas.width,
        height: canvas.height
      });
    };
  
    // Mettre à jour les dimensions immédiatement
    updateCanvasDimensions();
  
    // Observer les changements de taille du conteneur
    const resizeObserver = new ResizeObserver(updateCanvasDimensions);
    if (canvas && canvas.parentElement) {
      resizeObserver.observe(canvas.parentElement);
    }
  
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div 
      className="video-overlay-container" 
      style={{ 
        position: 'relative', 
        width: '100%', 
        height: '100%',
        overflow: 'hidden', 
        backgroundColor: 'black' 
      }}
    >
      {(isLoading || !videoReady) && (
        <div className="spinner-container" style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'rgba(0, 0, 0, 0.7)',
          borderRadius: '8px',
          padding: '20px',
          zIndex: 10
        }}>
          <div className="loading-spinner">
            <div className="pl">
              <svg className="pl__ring" viewBox="0 0 128 128" width="64px" height="64px">
                <circle 
                  className="pl__ring-track" 
                  r="40" 
                  cx="64" 
                  cy="64" 
                  fill="none" 
                  stroke="#E8E8E8" 
                  strokeWidth="8" 
                  strokeLinecap="round"
                />
                <circle 
                  className="pl__ring-fill" 
                  r="40" 
                  cx="64" 
                  cy="64" 
                  fill="none" 
                  stroke="#3B82F6" 
                  strokeWidth="8" 
                  strokeDasharray="251.2" 
                  strokeLinecap="round"
                />
              </svg>
              <div className="loading-text" style={{
                color: '#fff',
                marginTop: '10px',
                textAlign: 'center',
                fontSize: '0.8rem',
                whiteSpace: 'nowrap'
              }}>
                Initializing video...
              </div>
            </div>
          </div>
        </div>
      )}

      <video
        ref={videoRef}
        autoPlay
        playsInline
        muted
        className={videoReady ? '' : 'is-hidden'}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          objectFit: 'contain',
          pointerEvents: 'none'
        }}
      />
      <canvas
        ref={canvasRef}
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '100%',
          transform: 'translate(-50%, -50%)',
          objectFit: 'contain',
          pointerEvents: 'none'
        }}
      />
    </div>
  );
};

export default VideoOverlay;