// CountingSession.js
import React, { useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import VideoOverlay from './VideoOverlay';
import StreamStats from './StreamStats';
import StatsPanel from './StatsPanel';

const CountingSession = ({ 
  userContext, 
  onBack, 
  inventorySessionId,
  startInventorySession  
}) => {
  const [isCountButtonPressed, setIsCountButtonPressed] = useState(false);
  const [totalAccumulatedCount, setTotalAccumulatedCount] = useState(0);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentCount, setCurrentCount] = useState(0);
  const [manualAdjustment, setManualAdjustment] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [isSelectionLocked, setIsSelectionLocked] = useState(false);
  const [isVideoActive, setIsVideoActive] = useState(true);
  const [shouldShowVideo, setShouldShowVideo] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [maxCount, setMaxCount] = useState(0);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isEndingSession, setIsEndingSession] = useState(false);
  const [streamStats, setStreamStats] = useState({
    fps: 0,
    bitrate: 0,
    resolution: {
      width: 0,
      height: 0
    }
  });
  const [showStats, setShowStats] = useState(false);
  const [smoothedLatency, setSmoothedLatency] = useState(null);

  const initRef = useRef({ initialized: false, currentSessionId: null });
  const [isWebSocketReady, setIsWebSocketReady] = useState(false);

  const { socket, sendMessage, subscribe, isConnected } = useWebSocket();

  // États pour les notifications
  const [notification, setNotification] = useState({
    isVisible: false,
    message: '',
    type: 'is-info'
  });

  // État de chargement pour l'envoi
  const [isSending, setIsSending] = useState(false);

  // Fonction pour afficher une notification
  const showNotification = (message, type = 'is-info') => {
    setNotification({
      isVisible: true,
      message,
      type
    });
  
    setTimeout(() => {
      setNotification(prev => ({
        ...prev,
        isVisible: false
      }));
    }, 3000);
  };

  // Fonction de réinitialisation complète
  const resetCountingSession = (productName) => {
    // Arrêter le comptage en cours si actif
    if (isCounting) {
      handleCountEnd();
    }
    
    // Réinitialiser tous les compteurs
    setCurrentCount(0);
    setMaxCount(0);
    setTotalAccumulatedCount(0);
    setManualAdjustment(0);
    
    // Réinitialiser les états de comptage
    setIsCounting(false);
    setIsCountButtonPressed(false);
    
    // Déverrouiller et réinitialiser la sélection de produit
    setIsSelectionLocked(false);
    setSelectedProduct(null);
    
    // Réinitialiser les suggestions
    setSuggestedProducts([]);
    
    console.log('[CountingSession] Session reset completed for product:', productName);
  };

  // Stats handler
  const handleStatsUpdate = useCallback((stats) => {
    // console.log('[CountingSession] Stream stats update:', stats);
    setStreamStats(stats);  
  }, []);

  // Gestionnaires pour le bouton de comptage
  const handleCountStart = () => {

    console.log("[CountingSession] Starting count with state:", {
      selectedProduct,
      isSelectionLocked,
      currentCount,
      manualAdjustment
    });

    if (!initRef.current.initialized || !selectedProduct || !isSelectionLocked) {
      console.warn("[CountingSession] Cannot start counting - not initialized or product not selected");
      return;
    }

    setIsCountButtonPressed(true);
    setIsCounting(true);
    
    console.log("[CountingSession] Starting count with current state:", {
      currentCount,
      manualAdjustment,
      sessionId: initRef.current.currentSessionId
    });
    
    sendMessage({
      type: "start_counting",
      data: { 
        product_name: selectedProduct.name,
        current_count: currentCount,
        manual_adjustment: manualAdjustment,
        inventory_session_id: inventorySessionId
      }
    });
  };

  const handleCountEnd = useCallback(() => {
    if (!isCounting) return; // Éviter les appels multiples
    
    setIsCountButtonPressed(false);
    setIsCounting(false);
    
    console.log("[CountingSession] Stopping count with state:", {
      currentCount,
      manualAdjustment
    });
    
    sendMessage({
      type: "stop_counting",
      data: { 
        product_name: selectedProduct.name,
        current_count: currentCount,
        manual_adjustment: manualAdjustment,
        inventory_session_id: inventorySessionId
      }
    });
  }, [isCounting, currentCount, manualAdjustment, selectedProduct, inventorySessionId]);
  

  // Get products from userContext
  const products = useMemo(() => {
    return userContext?.products || [];
  }, [userContext]);

  // Start video on load
  useEffect(() => {
    const startVideo = async () => {
      if (!inventorySessionId || !userContext) return;
      
      setIsLoading(true);
      try {
        setIsVideoActive(true);
        setShouldShowVideo(true);
      } catch (error) {
        console.error('Error starting video:', error);
        setIsLoading(false);
      }
    };

    startVideo();

    return () => {
      setIsVideoActive(false);
      setShouldShowVideo(false);
      setIsLoading(false);
    };
  }, [inventorySessionId, userContext]);

  // Session and WebSocket management
  useEffect(() => {
    if (!socket || !inventorySessionId) {
      return;
    }

    const handleProductSuggestions = (data) => {
      if (data.data && data.data.products) {
          const sortedProducts = data.data.products.sort((a, b) => b.confidence - a.confidence);
          setSuggestedProducts(sortedProducts);
          
          // Ne mettre à jour la sélection que si le verrou est inactif
          if (!isSelectionLocked && sortedProducts.length > 0) {
              const highestConfidenceProduct = sortedProducts[0];
              const matchedProduct = products.find(p => p.name === highestConfidenceProduct.name);
              if (matchedProduct) {
                  setSelectedProduct(matchedProduct);
              }
          }
      }
    };

    // Gestionnaire pour les mises à jour de comptage
    const handleCountingUpdate = (data) => {
      console.log("[CountingSession] Received counting update:", data);
      if (data.data && data.data.is_counting) {
        const newCount = data.data.current_count || 0;
        const totalCount = data.data.total_count || 0;
        const newManualAdjustment = data.data.manual_adjustment || 0;
        
        setCurrentCount(newCount);
        setMaxCount(newCount);
        setTotalAccumulatedCount(totalCount);
        setManualAdjustment(newManualAdjustment);

        console.log("[CountingSession] Updated counts:", {
          currentCount: newCount,
          totalCount: totalCount,
          manualAdjustment: newManualAdjustment
        });
      }
    };

    const handleCountingStarted = (data) => {
      console.log("[CountingSession] Counting started:", data);
      if (data.product) {
          setIsCounting(true);
      }
    };

    const handleCountingStopped = (data) => {
      console.log("[CountingSession] Counting stopped:", data);
      setIsCounting(false);
      setIsCountButtonPressed(false);
      
      if (data.current_count !== undefined) {
        setCurrentCount(data.current_count);
        setMaxCount(data.current_count);
      }
      if (data.total_count !== undefined) {
        setTotalAccumulatedCount(data.total_count);
      }
      if (data.manual_adjustment !== undefined) {
        setManualAdjustment(data.manual_adjustment);
      }
    };

    const handleInventoryUpdate = (data) => {
      console.log("[CountingSession] Received inventory update response:", data);
      
      if (data.status === "success") {
        showNotification("Inventory updated successfully", "is-success");
        
        // Réinitialiser complètement la session
        if (selectedProduct) {
          resetCountingSession(selectedProduct.name);
        }
      } else {
        showNotification(data.message || "Error updating inventory", "is-danger");
      }
      setIsSending(false);
    };
  
    const handleInventoryReset = (data) => {
      console.log("[CountingSession] Received inventory reset:", data);
      if (data.data?.product_name === selectedProduct?.name) {
        resetCountingSession(data.data.product_name);
      }
    };

    // Gestionnaire pour le message "counting_session_ended"
    const handleCountingSessionEnded = (data) => {
        console.log("[CountingSession] Counting session ended:", data);
        // Réinitialiser de tous les états
        setIsEndingSession(true);
        setSelectedProduct(null);
        setCurrentCount(0);
        setMaxCount(0);
        setIsCounting(false);
        setIsSelectionLocked(false);
        setSuggestedProducts([]);
        setShowSpinner(false);
        setIsVideoActive(false);
        setShouldShowVideo(false);

        // Informer le parent que la session est terminée
        if (onBack) {
            onBack();
        }
    };

    // Subscribe to messages
    const unsubscribes = [
      subscribe('product_suggestions', handleProductSuggestions),
      subscribe('counting_update', handleCountingUpdate),
      subscribe('counting_started', handleCountingStarted),
      subscribe('counting_stopped', handleCountingStopped),
      subscribe('product_selected', (data) => {
        if (data.status === 'success') {
          // Trouver le produit correspondant dans la liste des produits
          const selectedProduct = products.find(p => p.name === data.product);
          if (selectedProduct) {
            setSelectedProduct(selectedProduct);
            setIsSelectionLocked(true); // Optionnel : verrouiller automatiquement la sélection
          }
        }
      }),

      subscribe('count_adjusted', (data) => {
        console.log("[CountingSession] Count adjusted:", data);
        if (data.new_count !== undefined) {
          setCurrentCount(data.new_count);
          setMaxCount(data.new_count);
        }
        if (data.manual_adjustment !== undefined) {
          setManualAdjustment(data.manual_adjustment);
        }
      }),

      // Gestionnaire pour la fin de session
      subscribe('counting_session_ended', handleCountingSessionEnded),

      subscribe('counting_inventory_update', handleInventoryUpdate),
      subscribe('counting_inventory_reset', handleInventoryReset)
    ];

    return () => {
      unsubscribes.forEach(unsub => unsub());
    };
  }, [socket, inventorySessionId, sendMessage, products, isSelectionLocked, onBack, isEndingSession, maxCount, isCounting, currentCount, manualAdjustment, selectedProduct]);

  const toggleLock = () => {
    const newLockState = !isSelectionLocked;
    setIsSelectionLocked(newLockState);
    
    if (newLockState && selectedProduct) {
      // Envoyer select_product uniquement quand on verrouille avec un produit sélectionné
      sendMessage({
        type: "select_product",
        data: { 
          product_name: selectedProduct.name,
          inventory_session_id: inventorySessionId
        }
      });
    }
    
    // Informer le backend du changement de verrouillage
    console.log("[CountingSession] Sending product_selection_locked message:", {
      product_name: selectedProduct?.name,
      is_locked: newLockState,
      inventory_session_id: inventorySessionId
    });
    sendMessage({
      type: "product_selection_locked",
      data: {
        product_name: selectedProduct?.name,
        is_locked: newLockState,
        inventory_session_id: inventorySessionId
      }
    });
  };

  const handleProductChange = (e) => {
    if (isSelectionLocked) return;
    
    const product = products.find(p => p.id === e.target.value);
    if (product) {
      setSelectedProduct(product);
      // Réinitialiser tous les compteurs pour le nouveau produit
      setCurrentCount(0);
      setMaxCount(0);
      setManualAdjustment(0);
      
      if (isCounting) {
        handleCountEnd();
      }
      
      sendMessage({
        type: "select_product",
        data: { 
          product_name: product.name,
          inventory_session_id: inventorySessionId
        }
      });
    }
  };

  const handleCount = () => {
    const newCountingState = !isCounting;
    setIsCounting(newCountingState);
    
    if (!newCountingState) {
      setCurrentCount(0);
      setMaxCount(0);
    }
    
    const message = {
      type: newCountingState ? "start_counting" : "stop_counting",
      data: { 
        product_name: selectedProduct.name,
        inventory_session_id: inventorySessionId
      }
    };
    
    console.log("[CountingSession] Sending count message:", message);
    sendMessage(message);
  };

  // Gestionnaire pour l'ajustement du comptage
  const adjustCount = (adjustment) => {
    if (isCountButtonPressed) return; // Désactivé pendant le comptage
    
    const newCount = Math.max(0, currentCount + adjustment);
    const newManualAdjustment = manualAdjustment + adjustment;
    
    setCurrentCount(newCount);
    setMaxCount(newCount);
    setManualAdjustment(newManualAdjustment);
    
    console.log("[CountingSession] Adjusting count:", {
      adjustment,
      newCount,
      newManualAdjustment
    });
    
    sendMessage({
      type: "adjust_count",
      data: {
        product_name: selectedProduct.name,
        adjustment: adjustment,
        current_count: newCount,
        manual_adjustment: newManualAdjustment,
        inventory_session_id: inventorySessionId
      }
    });
  };

  const handleSendInventory = async () => {
    if (!selectedProduct) {
      showNotification("Please select a product first", "is-warning");
      return;
    }
  
    if (currentCount === 0) {
      showNotification("Count cannot be zero", "is-warning");
      return;
    }
    
    setIsSending(true);
    showNotification("Sending inventory update...", "is-info");
    
    try {
      console.log("[CountingSession] Sending inventory update:", {
        product_name: selectedProduct.name,
        count: currentCount,
        user_id: userContext.user_id,
        inventory_session_id: inventorySessionId
      });
      
      sendMessage({
        type: "send_counting_inventory",
        data: {
          product_name: selectedProduct.name,
          count: currentCount,
          user_id: userContext.user_id,
          inventory_session_id: inventorySessionId
        }
      });
    } catch (error) {
      console.error("[CountingSession] Error sending inventory:", error);
      showNotification("Error sending inventory update", "is-danger");
      setIsSending(false);
    }
  };

  // Démarrage de session modifié
  useEffect(() => {
    let isMounted = true;

    const initSession = async () => {
      // Vérifier si déjà initialisé avec la même session
      if (initRef.current.initialized && initRef.current.currentSessionId === inventorySessionId) {
        console.log('[CountingSession] Session already initialized:', inventorySessionId);
        return;
      }

      console.log('[CountingSession] Checking initialization conditions:', {
        initialized: initRef.current.initialized,
        hasUserContext: !!userContext,
        isConnected,
        inventorySessionId
      });

      if (!userContext || !isConnected) {
        console.log('[CountingSession] Skipping initialization due to conditions not met');
        return;
      }

      console.log('[CountingSession] Starting initialization...');
      setShowSpinner(true);

      try {
        if (!inventorySessionId) {
          const sessionParams = {
            mode: 'counting',
            user_id: userContext.user_id,
            user_agent: navigator.userAgent,
            codec: 'vp8'
          };

          console.log('[CountingSession] Starting session with params:', sessionParams);
          const newSessionId = await startInventorySession(sessionParams);

          if (!isMounted) return;

          if (newSessionId) {
            console.log('[CountingSession] Session started successfully:', newSessionId);
            initRef.current.initialized = true;
            initRef.current.currentSessionId = newSessionId;
            setShouldShowVideo(true);
            setIsVideoActive(true);
          }
        } else {
          // Session existante
          console.log('[CountingSession] Using existing session:', inventorySessionId);
          initRef.current.initialized = true;
          initRef.current.currentSessionId = inventorySessionId;
          setShouldShowVideo(true);
          setIsVideoActive(true);
        }
      } catch (error) {
        console.error('[CountingSession] Error starting session:', error);
        setShowSpinner(false);
        setShouldShowVideo(false);
        setIsVideoActive(false);
      }
    };

    initSession();

    return () => {
      isMounted = false;
    };
  }, [userContext, inventorySessionId, startInventorySession, isConnected]);

  // Écouter l'état de la connexion WebSocket
  useEffect(() => {
    if (socket) {
      const handleOpen = () => setIsWebSocketReady(true);
      const handleClose = () => setIsWebSocketReady(false);

      socket.addEventListener('open', handleOpen);
      socket.addEventListener('close', handleClose);

      // Vérification de l'état initial
      if (socket.readyState === WebSocket.OPEN) {
        setIsWebSocketReady(true);
      }

      return () => {
        socket.removeEventListener('open', handleOpen);
        socket.removeEventListener('close', handleClose);
      };
    }
  }, [socket]);

  // Ajout d'un useEffect pour le nettoyage
  useEffect(() => {
    // Fonction de nettoyage qui sera appelée quand le composant est démonté
    return () => {
      console.log("[CountingSession] Cleaning up counting session...");
      if (socket) {
        // Informer le backend que la session counting se termine
        sendMessage({
          type: "end_counting_session",
          data: { inventory_session_id: inventorySessionId } 
        });
      }
    };
  }, [socket, inventorySessionId, sendMessage]);

  const handleBack = () => {
    if (!isEndingSession) {
      setIsEndingSession(true);
      
      // Envoyer le message de fin de session
      if (socket && inventorySessionId) {
        console.log('[CountingSession] Ending session:', inventorySessionId);
        sendMessage({
          type: "end_inventory_session", 
          data: { 
            inventory_session_id: inventorySessionId 
          }
        });
      }
      
      // Attendre un court instant pour laisser le temps au message d'être traité
      setTimeout(() => {
        if (onBack) {
          onBack();
        }
      }, 100);
    }
  };

  useEffect(() => {
    return () => {
      setIsEndingSession(false);
    };
  }, []);

    // Cleanup effect ajouté
    useEffect(() => {
      return () => {
          if (initRef.current.currentSessionId && !isEndingSession) {
              console.log('[CountingSession] Cleanup: ending session', initRef.current.currentSessionId);
              sendMessage({
                type: "end_inventory_session",
                data: { 
                  inventory_session_id: initRef.current.currentSessionId 
                }
              });
          }
          // Réinitialiser l'état
          initRef.current = { initialized: false, currentSessionId: null };
      };
    }, [sendMessage, isEndingSession]);

    return (
      <div className="min-vh-100 is-flex is-flex-direction-column" style={{ backgroundColor: '#192066' }}>
        {/* Header */}
        <div className="is-flex is-align-items-center py-1 px-2 is-justify-content-space-between has-background-white">
          <div className="is-flex is-align-items-center">
            <button className="button is-small mr-2 is-light" onClick={handleBack}>
              <span className="icon is-small">
                <i className="fas fa-chevron-left"></i>
              </span>
            </button>
            <h1 className="title is-6 mb-0">Counting Mode</h1>
          </div>
          
          <button 
            className="button is-small is-light"
            onClick={() => setShowStats(!showStats)}
          >
            <span className="icon">
              <i className="fas fa-info-circle"></i>
            </span>
          </button>
        </div>

        {/* Notification */}
        <div className="container px-2">
          {notification.isVisible && (
            <div className={`notification ${notification.type} is-light mb-2`} 
                style={{
                  position: 'fixed',
                  top: '60px',
                  left: '50%',
                  transform: 'translateX(-50%)',
                  zIndex: 1000,
                  width: '90%',
                  maxWidth: '400px',
                  borderRadius: '6px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  animation: 'slideDown 0.3s ease-out'
                }}>
              <button className="delete" onClick={() => setNotification(prev => ({ ...prev, isVisible: false }))}></button>
              {notification.message}
            </div>
          )}
        </div>
    
        {/* Stats Panel */}
        <div className={`px-2 ${!showStats && 'is-hidden'}`}>
          <StatsPanel stats={streamStats} smoothedLatency={smoothedLatency} />
        </div>
    
        {/* Main Content */}
        <div className="is-flex is-flex-direction-column" style={{ flex: 1, width: '100%' }}>
          {/* Video Container */}
          <div>
            <div className="box has-background-white p-2 mb-2" style={{ 
              marginLeft: 0,
              marginRight: 0,
              borderRadius: '0 0 12px 12px',
              borderTop: 'none'
            }}>
              <div 
                className="has-background-black" 
                style={{ 
                  borderRadius: '0 0 8px 8px',
                  overflow: 'hidden',
                  position: 'relative',
                  paddingTop: '100%',
                  boxShadow: isCountButtonPressed ? '0 0 0 4px rgba(255, 0, 0, 0.8)' : 'none',
                  transition: 'box-shadow 0.3s ease-in-out'
                }}
              >
                <div 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                >
                  {shouldShowVideo && (
                    <VideoOverlay
                      websocket={socket}
                      inventorySessionId={inventorySessionId}
                      mode="counting"
                      onVideoReady={() => setShowSpinner(false)}
                      userContext={userContext}
                      onStatsUpdate={handleStatsUpdate}
                      selectedProduct={selectedProduct}
                      isSelectionLocked={isSelectionLocked}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
    
          {/* Product Selection */}
          <div className="px-2">
            <div className="box p-2 mb-2">
              <label className="label">Product Selection</label>
              <div className="field mb-2">
                <div className="is-flex" style={{ gap: '0.5rem' }}>
                  <button 
                    className={`button ${isSelectionLocked ? 'is-danger' : 'is-success'}`} 
                    onClick={toggleLock}
                    disabled={!selectedProduct}
                    style={{ height: '2.5rem', width: '2.5rem' }}
                  >
                    <span className="icon">
                      <i className={`fas fa-${isSelectionLocked ? 'lock' : 'lock-open'} fa-lg`}></i>
                    </span>
                  </button>
                  <div className="select is-fullwidth">
                    <select 
                      value={selectedProduct?.id || ''} 
                      onChange={handleProductChange}
                      disabled={isSelectionLocked}
                      style={{ height: '2.5rem' }}
                    >
                      <option value="">Select a product...</option>
                      {products.map(product => (
                        <option key={product.id} value={product.id}>
                          {product.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
    
              <div className="is-flex is-align-items-center is-justify-content-space-between">
                <div className="is-flex is-align-items-center" style={{ gap: '0.5rem' }}>
                  <button 
                    className="button is-light is-rounded" 
                    onClick={() => adjustCount(-1)}
                    disabled={!selectedProduct || currentCount === 0}
                    style={{ height: '2.5rem', width: '2.5rem' }}
                  >
                    <i className="fas fa-minus"></i>
                  </button>
                  
                  <div className="title is-5 mb-0">{currentCount}</div>
                  
                  <button 
                    className="button is-light is-rounded" 
                    onClick={() => adjustCount(1)}
                    disabled={!selectedProduct}
                    style={{ height: '2.5rem', width: '2.5rem' }}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </div>
    
                <button
                  className={`button is-light is-rounded ${isSending ? 'is-loading' : ''}`}
                  onClick={handleSendInventory}
                  disabled={currentCount === 0 || !selectedProduct || isSending || isCounting}
                >
                  <span className="icon">
                    <i className="fas fa-paper-plane"></i>
                  </span>
                  <span>{isSending ? 'Sending...' : 'Send'}</span>
                </button>

              </div>
            </div>
          </div>
    
          {/* Capture Button */}
          <div className="is-flex is-justify-content-center" style={{ marginTop: 'auto', paddingBottom: '1rem' }}>
            <button
              onPointerDown={handleCountStart}
              onPointerUp={handleCountEnd}
              onPointerLeave={handleCountEnd}
              className={`button is-rounded ${isCountButtonPressed ? 'is-danger' : 'is-light'}`}
              style={{
                width: '70px',
                height: '70px',
                border: '2px solid #4a4a4a'
              }}
              disabled={!selectedProduct || !isSelectionLocked}
            >
              <span className="icon">
                <i className="fas fa-circle-dot fa-lg"></i>
              </span>
            </button>
          </div>
        </div>

        <style jsx>{`
        @keyframes slideDown {
          from {
            transform: translate(-50%, -100%);
            opacity: 0;
          }
          to {
            transform: translate(-50%, 0);
            opacity: 1;
          }
        }
      `}</style>

      </div>

    );

};

export default CountingSession;