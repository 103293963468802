// App.js
import React, { useEffect, useState, useCallback, useRef } from 'react';
import './App.css';
import 'bulma/css/bulma.min.css';
import { WebSocketProvider, useWebSocket } from './contexts/WebSocketContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import { jwtDecode } from 'jwt-decode';
import { getKeycloakInstance } from './services/auth';
import { InventoryProvider } from './contexts/InventoryContext';
import { SERVER_VIDEO_CONSTRAINTS } from './config/videoConfig';
import Navbar from './components/Navbar';
import InventoryNavigation from './components/InventoryNavigation';
import './bulma-custom.scss';

const App = () => {
  const { user, authenticated } = useAuth();
  const [userContext, setUserContext] = useState(null);
  const [inventorySessionId, setInventorySessionId] = useState(null);

  // Mode de comptage ou de tag
  const [mode, setMode] = useState(null);

  // Initialize state in 'rack' mode and set selectedEntity to null
  const [state, setState] = useState({
    selectedEntity: null,
    zone: null,
    rack: null,
    shelf: null,
    showZoneSelection: true
  });

  const { socket, sendMessage, subscribe } = useWebSocket();
  const userContextAttemptsRef = useRef(0);
  const MAX_USER_CONTEXT_ATTEMPTS = 3;

  const handleModeChange = async (newMode) => {
    if (newMode === 'counting' || newMode === 'sweep') {
      try {
        await startInventorySession({ mode: newMode });
      } catch (error) {
        console.error(`Failed to start ${newMode} session:`, error);
      }
    }
    setMode(newMode);
  };

  // Function to request user context
  const getUserContext = useCallback(() => {
    console.log('getUserContext called', { 
      attempts: userContextAttemptsRef.current, 
      maxAttempts: MAX_USER_CONTEXT_ATTEMPTS, 
      userContext: !!userContext,
      socketReady: socket && socket.readyState === WebSocket.OPEN,
      userAuthenticated: !!user && !!user.token
    });

    if (userContextAttemptsRef.current >= MAX_USER_CONTEXT_ATTEMPTS || userContext) {
      console.log('Max attempts reached or user context already set');
      return;
    }

    if (socket && socket.readyState === WebSocket.OPEN && user && user.token) {
      console.log('Requesting user context...');
      try {
        const decodedToken = jwtDecode(user.token);
        const sub = decodedToken.sub;
        console.log('Decoded token sub:', sub);

        // Set up subscriptions to handle 'user_context' and 'user_context_error'
        const unsubscribes = [];

        const handleUserContext = (data) => {
          console.log('Received user context:', data.data);
          setUserContext(data.data);
          localStorage.setItem('userContext', JSON.stringify(data.data));
          unsubscribes.forEach(unsub => unsub());
        };

        const handleUserContextError = (data) => {
          console.error('Error getting user context:', data.error);
          unsubscribes.forEach(unsub => unsub());
        };

        unsubscribes.push(subscribe('user_context', handleUserContext));
        unsubscribes.push(subscribe('user_context_error', handleUserContextError));

        sendMessage({
          type: 'get_user_context',
          sub: sub
        });
        userContextAttemptsRef.current += 1;

        // Optionally, set a timeout to prevent waiting indefinitely
        setTimeout(() => {
          unsubscribes.forEach(unsub => unsub());
        }, 5000);

      } catch (error) {
        console.error('Error decoding JWT:', error);
      }
    } else {
      console.error('WebSocket is not open or user is not authenticated. Cannot request user context.', {
        socketOpen: socket && socket.readyState === WebSocket.OPEN,
        userAuthenticated: !!user && !!user.token
      });
    }
  }, [socket, user, sendMessage, userContext, subscribe]);

  // Request user context when authenticated
  useEffect(() => {
    if (authenticated && socket && !userContext) {
      const storedContext = localStorage.getItem('userContext');
      if (storedContext) {
        console.log('Using stored user context');
        setUserContext(JSON.parse(storedContext));
      } else {
        console.log('No stored user context found, requesting from server');
        getUserContext();
      }
    }
  }, [authenticated, socket, getUserContext, userContext]);

  // Function to start inventory session
  const startInventorySession = async (sessionData = {}) => {
    console.log("[Apps] Starting inventory session - startInventorySession()");

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: SERVER_VIDEO_CONSTRAINTS });
      const track = stream.getVideoTracks()[0];
      const settings = track.getSettings();
      
      // Arrêter le stream après avoir obtenu les dimensions
      track.stop();
      
      console.log(`Video dimensions: ${settings.width}x${settings.height}`);

      const sessionConfig = {
        mode: sessionData.mode || 'tagzone',
        user_id: userContext?.user_id || user?.id,
        width: settings.width,
        height: settings.height,
        user_agent: navigator.userAgent,
        codec: 'vp8',
        token: user?.token,
        user_context: userContext,
        rack_id: state.rack ? state.rack.id : null,
        ...sessionData
      };

      // Set up subscriptions to handle responses
      return new Promise((resolve, reject) => {
        const unsubscribes = [];

        const handleResponse = (data) => {
          console.log('Received inventory session ID:', data.inventory_session_id);
          setInventorySessionId(data.inventory_session_id);
          unsubscribes.forEach(unsub => unsub());
          resolve(data.inventory_session_id);
        };

        const handleError = (data) => {
          console.error('Error starting inventory session:', data.message);
          unsubscribes.forEach(unsub => unsub());
          reject(new Error(data.message));
        };

        unsubscribes.push(subscribe('start_inventory_session_response', handleResponse));
        unsubscribes.push(subscribe('start_inventory_session_error', handleError));
        unsubscribes.push(subscribe('mode_change_error', handleError));

        sendMessage({
          type: 'start_inventory_session',
          ...sessionConfig
        });

        // Timeout after 10 seconds
        setTimeout(() => {
          unsubscribes.forEach(unsub => unsub());
          reject(new Error('Timeout starting inventory session'));
        }, 10000);
      });
    } catch (error) {
      console.error('Error starting inventory session:', error);
      throw error;
    }
  };

  const handleModeSelect = (mode) => {
    setState(prevState => ({
      ...prevState,
      mode,
      selectedEntity: null,
      zone: null,
      rack: null,
      shelf: null,
      showSelectMessage: mode !== 'rack',
      showZoneSelection: mode === 'rack'
    }));
  };

  const updateBreadcrumb = useCallback(() => {
    const breadcrumbItems = [];
  
    // Function to truncate text
    const truncate = (text, length = 10) => {
      return text.length > length ? text.substring(0, length) + '...' : text;
    };
  
    // Home - Return to entity selection
    breadcrumbItems.push({
      text: 'Home',
      icon: 'fa-home',
      onClick: () => {
        setState(prevState => ({
          ...prevState,
          selectedEntity: null,
          zone: null,
          rack: null,
          shelf: null,
          showZoneSelection: true
        }));
        setMode(null); // Réinitialiser le mode
      },
      isHome: true
    });
  
    // Entity
    if (state.selectedEntity) {
      breadcrumbItems.push({
        text: truncate(state.selectedEntity.name),
        icon: 'fa-building',
        onClick: () => {
          if (mode === 'tagzone') {
            // En mode tagzone, retourner à la sélection de zone
            setState(prev => ({ ...prev, zone: null, rack: null, shelf: null }));
          } else {
            // Pour les autres modes, retourner à la sélection de mode
            setMode(null);
          }
        }
      });
    }
  
    // Storage Area
    if (state.zone) {
      breadcrumbItems.push({
        text: truncate(state.zone.name),
        icon: 'fa-warehouse',
        onClick: () => setState(prev => ({ ...prev, rack: null, shelf: null }))
      });
    }
  
    // Rack
    if (state.rack) {
      breadcrumbItems.push({
        text: truncate(state.rack.name),
        icon: 'fa-th',
        onClick: () => setState(prev => ({ ...prev, shelf: null }))
      });
    }
  
    // Shelf (if applicable)
    if (state.shelf) {
      breadcrumbItems.push({ text: truncate(state.shelf.name), icon: 'fa-layer-group' });
    }
    
    return (
      <nav className="breadcrumb has-succeeds-separator is-small" aria-label="breadcrumbs">
        <ul className="is-flex-wrap-nowrap">
          {breadcrumbItems.map((item, index) => (
            <li key={index} className={index === breadcrumbItems.length - 1 ? 'is-active' : ''}>
              {item.onClick ? (
                <button onClick={item.onClick} className="breadcrumb-link button is-text p-1">
                  <span className="icon is-small">
                    <i className={`fas ${item.icon}`} aria-hidden="true"></i>
                  </span>
                  <span className="breadcrumb-text">{item.text}</span>
                </button>
              ) : (
                <span className="p-1">
                  <span className="icon is-small">
                    <i className={`fas ${item.icon}`} aria-hidden="true"></i>
                  </span>
                  <span className="breadcrumb-text">{item.text}</span>
                </span>
              )}
            </li>
          ))}
        </ul>
      </nav>
    );
  }, [state, mode]);
  
  useEffect(() => {
    updateBreadcrumb();
  }, [updateBreadcrumb]);

  // Handle URL parameters (e.g., after logout)
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    if (params.get('state') === 'logged-out') {
      // User has been redirected back after logout
      window.history.replaceState(null, '', window.location.pathname);
      // Optionally, you can force a page reload here
      // window.location.reload();
    }
  }, []);

  if (!authenticated) {
    const keycloak = getKeycloakInstance();
    if (keycloak) {
      keycloak.login();
    }
    return null;
  }

  return (
    <div className="App">
      {mode !== 'counting' && mode !== 'sweep' ? (
        <section className="section has-background-white py-2">
          {updateBreadcrumb()}
        </section>
      ) : null}

      <div id="content">
        {userContext ? (
          state.showZoneSelection && (
            <InventoryNavigation 
              userContext={userContext} 
              state={state} 
              setState={setState}
              startInventorySession={startInventorySession}
              inventorySessionId={inventorySessionId}
              mode={mode}
              onModeChange={handleModeChange}
            />
          )
        ) : (
          <div>Loading user context...</div>
        )}
      </div>

      <Navbar
        mode={mode}
        onModeChange={handleModeChange}
      />
    </div>
  );
};

const AppWithProviders = () => (
  <AuthProvider>
    <WebSocketProvider>
      <InventoryProvider>
        <App />
      </InventoryProvider>
    </WebSocketProvider>
  </AuthProvider>
);

export default AppWithProviders;
