// src/config/videoConfig.js

// Configuration de base commune
const BASE_VIDEO_CONSTRAINTS = {
  facingMode: { ideal: 'environment' }, // Pour la caméra arrière
  aspectRatio: { exact: 1 }, // Format carré pour tous les flux
};

// Configuration pour le flux local haute qualité
export const LOCAL_VIDEO_CONSTRAINTS = {
  ...BASE_VIDEO_CONSTRAINTS,
  width: { ideal: 640 },
  height: { ideal: 640 },
  frameRate: { ideal: 30 }
};

// Configuration pour le flux serveur
export const SERVER_VIDEO_CONSTRAINTS = {
  ...BASE_VIDEO_CONSTRAINTS,
  width: { min: 640, ideal: 640, max: 640 },
  height: { min: 640, ideal: 640, max: 640 },
  frameRate: { ideal: 8 }
};

// Configuration du codec
export const CODEC = 'vp8';