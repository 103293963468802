// ModeSelection.js

import React from 'react';

const ModeSelection = ({ onModeSelect }) => {
  return (
    <div className="container">
      <h2 className="title is-3 has-text-centered mb-6">Select Inventory Mode</h2>
      
      <div className="columns is-centered">
        {/* TagZone Mode */}
        <div className="column is-one-third">
          <div 
            className="card mode-card" 
            onClick={() => onModeSelect('tagzone')}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-content has-text-centered">
              <span className="icon is-large">
                <i className="fas fa-tags fa-2x"></i>
              </span>
              <p className="title is-4 mt-4">TagZone</p>
              <p className="subtitle is-6">Zone-based inventory management</p>
            </div>
          </div>
        </div>

        {/* Counting Mode */}
        <div className="column is-one-third">
          <div 
            className="card mode-card" 
            onClick={() => onModeSelect('counting')}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-content has-text-centered">
              <span className="icon is-large">
                <i className="fas fa-calculator fa-2x"></i>
              </span>
              <p className="title is-4 mt-4">Counting</p>
              <p className="subtitle is-6">Quick product counting</p>
            </div>
          </div>
        </div>

        {/* Sweep Mode */}
        <div className="column is-one-third">
          <div 
            className="card mode-card" 
            onClick={() => onModeSelect('sweep')}
            style={{ cursor: 'pointer' }}
          >
            <div className="card-content has-text-centered">
              <span className="icon is-large">
                <i className="fas fa-broom fa-2x"></i>
              </span>
              <p className="title is-4 mt-4">Sweep</p>
              <p className="subtitle is-6">Line-based counting</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModeSelection; 