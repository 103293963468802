import React from 'react';

const StatsPanel = ({ stats, smoothedLatency }) => {
  // Définition des couleurs avec opacité réduite
  const colors = {
    red: 'rgba(255, 0, 0, 0.6)',
    orange: 'rgba(255, 153, 0, 0.6)',
    green: 'rgba(0, 255, 0, 0.6)',
    dark: 'rgba(23, 23, 23, 0.9)' // Fond sombre pour le texte
  };

  // Logique de coloration pour le FPS
  const getFpsBackground = (fps) => {
    if (!fps) return colors.dark;
    if (fps < 5) return colors.red;
    if (fps < 8) return colors.orange;
    return colors.green;
  };

  // Logique de coloration pour le bitrate
  const getBitrateBackground = (bitrate) => {
    if (!bitrate) return colors.dark;
    if (bitrate < 1000) return colors.red;      // < 1 Mbps
    if (bitrate < 1500) return colors.orange;  // < 1.5 Mbps
    return colors.green;                       // >= 1.5 Mbps
  };

  // Logique de coloration pour la résolution
  const getResolutionBackground = (width, height) => {
    if (!width || !height) return colors.dark;
    const pixels = width * height;
    if (pixels < 500 * 500) return colors.red;    // < 0.25MP
    if (pixels < 640 * 640) return colors.orange;  // < 0.4MP
    return colors.green;                          // >= 0.4MP
  };

  // Logique de coloration pour la latence
  const getLatencyColor = (latency) => {
    if (!latency) return colors.dark;
    if (latency >= 1000) return colors.red;     // > 1s
    if (latency >= 500) return colors.orange;  // > 500ms
    return colors.green;                       // < 500ms
  };

  // Fonctions de formatage
  const formatBitrate = (bitrate) => {
    if (!bitrate) return '0 kbps';
    if (bitrate >= 1000) return `${(bitrate / 1000).toFixed(1)} Mbps`;
    return `${Math.round(bitrate)} kbps`;
  };

  const formatResolution = (resolution) => {
    if (!resolution?.width || !resolution?.height) return 'N/A';
    return `${resolution.width}x${resolution.height}`;
  };

  const formatLatency = (latency) => {
    if (!latency) return 'n/a';
    if (latency >= 1000) return `${(latency / 1000).toFixed(1)}s`;
    return `${Math.round(latency)}ms`;
  };

  const baseBoxStyle = {
    minWidth: '90px',
    flexShrink: 0,
    height: 'fit-content'
  };

  const baseTitleStyle = {
    lineHeight: '1.2'
  };

  return (
    <div className="is-flex is-justify-content-space-around is-flex-wrap-nowrap" 
         style={{ gap: '0.25rem', overflowX: 'auto', padding: '0.5rem 0' }}>
      {/* FPS */}
      <div className="box p-1" style={{ 
        ...baseBoxStyle,
        backgroundColor: getFpsBackground(stats.fps),
      }}>
        <p className="heading is-size-7 has-text-white mb-1">FPS</p>
        <p className="title is-6 has-text-white has-text-weight-semibold" style={baseTitleStyle}>
          {stats.fps?.toFixed(1) || '0.0'}
        </p>
      </div>

      {/* Bitrate */}
      <div className="box p-1" style={{ 
        ...baseBoxStyle,
        backgroundColor: getBitrateBackground(stats.bitrate),
      }}>
        <p className="heading is-size-7 has-text-white mb-1">Bitrate</p>
        <p className="title is-6 has-text-white has-text-weight-semibold" style={baseTitleStyle}>
          {formatBitrate(stats.bitrate)}
        </p>
      </div>

      {/* Résolution */}
      <div className="box p-1" style={{ 
        ...baseBoxStyle,
        backgroundColor: getResolutionBackground(stats.resolution?.width, stats.resolution?.height),
      }}>
        <p className="heading is-size-7 has-text-white mb-1">Resolution</p>
        <p className="title is-6 has-text-white has-text-weight-semibold" style={baseTitleStyle}>
          {formatResolution(stats.resolution)}
        </p>
      </div>

      {/* Latence */}
      <div className="box p-1" style={{ 
        ...baseBoxStyle,
        backgroundColor: getLatencyColor(smoothedLatency),
      }}>
        <p className="heading is-size-7 has-text-white mb-1">Latency</p>
        <p className="title is-6 has-text-white has-text-weight-semibold" style={baseTitleStyle}>
          {formatLatency(smoothedLatency)}
        </p>
      </div>
    </div>
  );
};

export default StatsPanel; 