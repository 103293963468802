// SweepSession.js
import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useWebSocket } from '../contexts/WebSocketContext';
import VideoOverlay from './VideoOverlay';
import StreamStats from './StreamStats';
import StatsPanel from './StatsPanel';

const SweepSession = ({ 
  userContext, 
  onBack, 
  inventorySessionId,
  startInventorySession  
}) => {
    
  // États de base
  const [lineOrientation, setLineOrientation] = useState('horizontal');
  const [lineDirection, setLineDirection] = useState('up_to_down');  // Par défaut : objets du haut vers le bas
  const [linePosition, setLinePosition] = useState(75.0);  // Position à 75% de la hauteur

  // États pour la gestion de la sélection de produit
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isSelectionLocked, setIsSelectionLocked] = useState(false);
  const [currentCount, setCurrentCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  // État pour le bouton de capture
  const [isCountButtonPressed, setIsCountButtonPressed] = useState(false);
  const [isCounting, setIsCounting] = useState(false);
  const [manualAdjustment, setManualAdjustment] = useState(0);
  const [videoDimensions, setVideoDimensions] = useState({ width: 640, height: 640 });

  const [classCounters, setClassCounters] = useState({});

  // États pour la gestion du stream vidéo
  const [streamStats, setStreamStats] = useState({
    fps: 0,
    bitrate: 0,
    resolution: { width: 0, height: 0 },
    latency: 0
  });
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [shouldShowVideo, setShouldShowVideo] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [isEndingSession, setIsEndingSession] = useState(false);
  const [isWebSocketReady, setIsWebSocketReady] = useState(false);
  // États pour la gestion de la stats panel
  const [showStats, setShowStats] = useState(false);
  const [smoothedLatency, setSmoothedLatency] = useState(null);

  const [backgroundColor, setBackgroundColor] = useState('black'); // État pour la couleur de fond de la div video

  // Références pour le suivi de l'état
  const initRef = useRef({ initialized: false, currentSessionId: null });
  const isInitialized = useRef(false);

  // Hooks WebSocket
  const { socket, isConnected, sendMessage, subscribe } = useWebSocket();

  // Handler pour les stats du stream
  const handleStatsUpdate = useCallback((newStats) => {
    // console.log('Stats update received:', newStats);
    setStreamStats(newStats);
  }, []);

  // Get products from userContext
  const products = useMemo(() => {
    return userContext?.products || [];
  }, [userContext]);

  // États pour l'envoi
  const [isSending, setIsSending] = useState(false);

  // Ajouter au début du composant SweepSession, avec les autres états
  const [notification, setNotification] = useState({
    isVisible: false,
    message: '',
    type: 'is-info'
  });

  // Ajouter la fonction showNotification
  const showNotification = (message, type = 'is-info') => {
    setNotification({
      isVisible: true,
      message,
      type
    });

    setTimeout(() => {
      setNotification(prev => ({
        ...prev,
        isVisible: false
      }));
    }, 3000);
  };

  // Effet pour gérer le changement d'orientation
  useEffect(() => {
    // Met à jour la direction en fonction de la nouvelle orientation
    const newDirection = getDefaultDirection(lineOrientation);
    setLineDirection(newDirection);
    
    // Configure la ligne si nécessaire
    if (socket && isWebSocketReady && inventorySessionId) {
      configureSweepLine();
    }
  }, [lineOrientation]); 

  // Fonction utilitaire pour obtenir la direction par défaut selon l'orientation
  const getDefaultDirection = (orientation) => {
    switch (orientation) {
      case 'horizontal':
        return 'down_to_up';     // Direction par défaut pour l'orientation horizontale
      case 'vertical':
        return 'left_to_right';  // Direction par défaut pour l'orientation verticale
      default:
        return 'down_to_up';     // Fallback sur la direction horizontale par défaut
    }
  };

  // Gestionnaire de sélection de produit
  const handleProductChange = (e) => {
    if (isSelectionLocked) return;
    
    const product = products.find(p => p.id === e.target.value);
    if (product) {
      // console.log("[SweepSession] Sending select_product message:", {
      //   product_name: product.name,
      //   inventory_session_id: inventorySessionId
      // });

      setSelectedProduct(product);
      sendMessage({
        type: "select_product",
        data: { 
          product_name: product.name,
          inventory_session_id: inventorySessionId
        }
      });
    }
  };

  // Gestionnaire de verrouillage
  const toggleLock = () => {
    const newLockState = !isSelectionLocked;
    setIsSelectionLocked(newLockState);
    
    if (selectedProduct) {
      // Arrêter le comptage si on déverrouille
      if (isCounting && !newLockState) {
        handleCountEnd();
      }
      
      sendMessage({
        type: "product_selection_locked",
        data: {
          product_name: selectedProduct.name,
          is_locked: newLockState,
          inventory_session_id: inventorySessionId
        }
      });
    }
  };

  const handleCountStart = (e) => {
    e.preventDefault();
    
    console.log("[SweepSession] Starting count:", {
      selectedProduct,
      isSelectionLocked,
      currentCount,
      isCounting
    });
    
    if (!selectedProduct || !isSelectionLocked) {
      console.warn("Cannot start - missing product or not locked");
      return;
    }
    
    setIsCountButtonPressed(true);
    setIsCounting(true);

    setBackgroundColor('red'); // Changer la couleur de fond en rouge

    const message = {
      type: "start_counting",
      data: {
        product_name: selectedProduct.name,
        current_count: currentCount,
        inventory_session_id: inventorySessionId
      }
    };
    
    console.log("[SweepSession] Sending start message:", message);
    sendMessage(message);
  };

  const handleCountEnd = useCallback((e) => {
    if (e) e.preventDefault();
    
    console.log("[SweepSession] Handling count end:", {
      isCounting,
      selectedProduct,
      currentCount,
      manualAdjustment,
      isCountButtonPressed
    });
    
    if (!isCounting) {
      console.log("[SweepSession] Not counting, ignoring end event");
      return;
    }
    
    setIsCountButtonPressed(false);
    setIsCounting(false);

    setBackgroundColor('black'); // Réinitialiser la couleur de fond
    
    const message = {
      type: "stop_counting",
      data: {
        product_name: selectedProduct.name,
        current_count: currentCount,
        manual_adjustment: manualAdjustment,
        inventory_session_id: inventorySessionId
      }
    };
    
    console.log("[SweepSession] Sending stop message:", message);
    sendMessage(message);
  }, [isCounting, currentCount, manualAdjustment, selectedProduct, inventorySessionId, sendMessage]);

  // Configuration de la ligne de sweep
  const configureSweepLine = useCallback(() => {
    console.log('[DEBUG] Configuring line:', lineOrientation, lineDirection);
    if (socket && videoDimensions) {
      console.log('[DEBUG] Sending sweep config:', {
        width: videoDimensions.width,
        height: videoDimensions.height
      });
      sendMessage({
        type: "configure_sweep_line",
        data: {
          orientation: lineOrientation,
          direction: lineDirection,
          width: videoDimensions.width,
          height: videoDimensions.height,
          inventory_session_id: inventorySessionId
        }
      });
    }
  }, [socket, lineOrientation, lineDirection, videoDimensions, inventorySessionId, sendMessage]);

  // Handler pour la mise à jour des dimensions vidéo
  const handleVideoResolutionChange = useCallback((newWidth, newHeight) => {
    const validWidth = newWidth > 0 ? newWidth : 640;
    const validHeight = newHeight > 0 ? newHeight : 640;
    setVideoDimensions({ width: validWidth, height: validHeight });
    configureSweepLine();
  }, [configureSweepLine]); 

  // Effet pour reconfigurer la ligne quand les dimensions changent
  useEffect(() => {
    if (videoDimensions.width > 0 && videoDimensions.height > 0) {
      configureSweepLine();
    }
  }, [videoDimensions, configureSweepLine]);

  // Réinitialiser les compteurs
  const resetCounters = useCallback(() => {
    if (socket && inventorySessionId) {
      sendMessage({
        type: 'reset_sweep_count',
        inventory_session_id: inventorySessionId
      });
    }
    setTotalCount(0);
    setClassCounters({});
  }, [socket, inventorySessionId, sendMessage]);

  // Écouter l'état de la connexion WebSocket
  useEffect(() => {
    if (socket) {
      const handleOpen = () => setIsWebSocketReady(true);
      const handleClose = () => setIsWebSocketReady(false);

      socket.addEventListener('open', handleOpen);
      socket.addEventListener('close', handleClose);

      // Vérification de l'état initial
      if (socket.readyState === WebSocket.OPEN) {
        setIsWebSocketReady(true);
      }

      return () => {
        socket.removeEventListener('open', handleOpen);
        socket.removeEventListener('close', handleClose);
      };
    }
  }, [socket]);

  // Démarrage de session modifié
  useEffect(() => {
    let isMounted = true;

    const initSession = async () => {
        // Vérifier si déjà initialisé avec la même session
        if (initRef.current.initialized && initRef.current.currentSessionId === inventorySessionId) {
            console.log('[SweepSession] Session already initialized:', inventorySessionId);
            return;
        }

        console.log('[SweepSession] Checking initialization conditions:', {
            initialized: initRef.current.initialized,
            hasUserContext: !!userContext,
            isConnected,
            inventorySessionId
        });

        if (!userContext || !isConnected) {
            console.log('[SweepSession] Skipping initialization due to conditions not met');
            return;
        }

        console.log('[SweepSession] Starting initialization...');
        setShowSpinner(true);

        try {
            if (!inventorySessionId) {
                const sessionParams = {
                    mode: 'sweep',
                    user_id: userContext.user_id,
                    user_agent: navigator.userAgent,
                    codec: 'vp8'
                };

                console.log('[SweepSession] Starting session with params:', sessionParams);
                const newSessionId = await startInventorySession(sessionParams);
                
                if (!isMounted) return;

                if (newSessionId) {
                    console.log('[SweepSession] Session started successfully:', newSessionId);
                    initRef.current.initialized = true;
                    initRef.current.currentSessionId = newSessionId;
                    setShouldShowVideo(true);
                    setIsVideoActive(true);
                }
            } else {
                // Session existante
                console.log('[SweepSession] Using existing session:', inventorySessionId);
                initRef.current.initialized = true;
                initRef.current.currentSessionId = inventorySessionId;
                setShouldShowVideo(true);
                setIsVideoActive(true);
            }
        } catch (error) {
            console.error('[SweepSession] Error starting session:', error);
            setShowSpinner(false);
            setShouldShowVideo(false);
            setIsVideoActive(false);
        }
    };

    initSession();

    return () => {
        isMounted = false;
    };
  }, [userContext, isConnected, inventorySessionId, startInventorySession]);

  // Gestion du retour et nettoyage
  const handleBack = useCallback(() => {
    if (!isEndingSession) {
      setIsEndingSession(true);
      
      if (socket && inventorySessionId) {
        console.log('[SweepSession] Ending session:', inventorySessionId);
        sendMessage({
          type: "end_inventory_session",
          data: { 
            inventory_session_id: inventorySessionId 
          }
        });
      }
      
      // Attendre un court instant pour laisser le temps au message d'être traité
      setTimeout(() => {
        if (onBack) {
          onBack();
        }
      }, 100);
    }
  }, [socket, inventorySessionId, sendMessage, onBack, isEndingSession]);

  // Cleanup effect
  useEffect(() => {
    return () => {
        if (initRef.current.currentSessionId && !isEndingSession) {
            console.log('[SweepSession] Cleanup: ending session', initRef.current.currentSessionId);
            sendMessage({
                type: "end_inventory_session",
                data: { 
                    inventory_session_id: initRef.current.currentSessionId 
                }
            });
        }
        // Réinitialiser l'état
        initRef.current = { initialized: false, currentSessionId: null };
    };
  }, [sendMessage, isEndingSession]);

  // Souscription aux messages WebSocket
  useEffect(() => {
    if (!socket || !inventorySessionId) return;

    const unsubscribes = [

    // Gestionnaire dédié pour les ajustements
    subscribe('count_adjusted', (data) => {
      if (data.product === selectedProduct?.name) {
        setCurrentCount(data.new_count);
        setManualAdjustment(data.manual_adjustment);
        setTotalCount(data.total_count || 0);
      }
    }),

    // Gestionnaire pour les erreurs d'ajustement
    subscribe('adjustment_error', (data) => {
      if (data.product === selectedProduct?.name) {
        // Restaurer les valeurs précédentes en cas d'erreur
        setCurrentCount(data.previous_count || 0);
        setManualAdjustment(data.previous_adjustment || 0);
        // Afficher l'erreur à l'utilisateur
        alert(`Error adjusting count: ${data.error}`);
      }
    }),

    // Suggestions de produits
    subscribe('product_suggestions', (data) => {
      if (!isSelectionLocked && data.data?.products) {
        const sortedProducts = data.data.products.sort((a, b) => b.confidence - a.confidence);
        if (sortedProducts.length > 0) {
          const highestConfidenceProduct = sortedProducts[0];
          const matchedProduct = products.find(p => p.name === highestConfidenceProduct.name);
          if (matchedProduct) {
            setSelectedProduct(matchedProduct);
          }
        }
      }
    }),

    // Mises à jour du sweep
    subscribe('sweep_update', (data) => {
      const payload = data.data;
      if (payload && selectedProduct?.name === payload.product_name) {
        if (payload.current_count !== undefined) {
          setCurrentCount(payload.current_count);
        }
        if (payload.total_count !== undefined) {
          setTotalCount(payload.total_count);
        }
      }
    }),

    // Confirmation de début de comptage
    subscribe('counting_started', (data) => {
      if (data.product === selectedProduct?.name) {
        setIsCounting(true);
        setCurrentCount(data.current_count || 0);
      }
    }),

    // Confirmation de fin de comptage
    subscribe('counting_stopped', (data) => {
      if (data.product === selectedProduct?.name) {
        setIsCounting(false);
        setIsCountButtonPressed(false);
      }
    }),

    // Confirmation d'envoi d'inventaire
    subscribe('sweep_inventory_sent', (data) => {
      if (data.data?.status === 'success') {
        // Réinitialiser si nécessaire
      }
    }),

    // Confirmation de la configuration de la ligne
    subscribe('sweep_line_configured', (payload) => {
      console.log("[SweepSession] Received sweep_line_configured:", payload);
      
      if (payload.data) {
        const { orientation, direction, line_coords } = payload.data;
        console.log("[SweepSession] New line configuration:", {
          orientation,
          direction,
          line_coords
        });
      }
    }),

    // Gestion des erreurs
    subscribe('sweep_error', (payload) => {
      console.error('Sweep error:', payload.error);
      alert('Error: ' + payload.error);
    }),

    // Réponse à l'envoi d'inventaire
    subscribe('counting_inventory_update', (data) => {
      console.log("[SweepSession] Received inventory update response:", data);
      setIsSending(false);
      
      if (data.status === "success") {
        showNotification("Inventory updated successfully", "is-success");
      } else {
        showNotification(data.message || "Error updating inventory", "is-danger");
      }
    }),

    // Réinitialisation après envoi réussi
    subscribe('counting_inventory_reset', (data) => {
      console.log("[SweepSession] Received inventory reset:", data);
      if (data.data?.product_name === selectedProduct?.name) {
        setCurrentCount(0);
        setTotalCount(0);
        setManualAdjustment(0);
        setIsCounting(false);
        setIsCountButtonPressed(false);
        setIsSelectionLocked(false);
        setSelectedProduct(null);
      }
    })
  ];

  // Configuration initiale de la ligne
  configureSweepLine();

    return () => {
      unsubscribes.forEach(unsub => unsub());
    };
  }, [
    socket, 
    inventorySessionId, 
    subscribe, 
    isSelectionLocked, 
    selectedProduct, 
    products, 
    configureSweepLine,
    isCountButtonPressed,
    isSending
  ]);

  // Log le changement de produit sélectionné
  useEffect(() => {
      console.log('📝 Selected product changed:', {
          product: selectedProduct?.name,
          isLocked: isSelectionLocked,
          timestamp: new Date().toISOString()
      });
  }, [selectedProduct, isSelectionLocked]);

  // Effet pour la configuration initiale explicite
  useEffect(() => {
    if (socket && isWebSocketReady && inventorySessionId) {
        // Configuration initiale avec la ligne dans le quart inférieur
        const initialConfig = {
            type: "configure_sweep_line",
            data: {
                orientation: 'horizontal',
                direction: 'up_to_down',
                position: 75.0,  // Quart inférieur par défaut
                width: videoDimensions.width || 640,
                height: videoDimensions.height || 640,
                inventory_session_id: inventorySessionId
            }
        };
        sendMessage(initialConfig);
    }
  }, [socket, isWebSocketReady, inventorySessionId]);

  // Ajouter un useEffect pour la configuration initiale
  useEffect(() => {
    console.log('Current sweep line config:', { 
      orientation: lineOrientation,
      direction: lineDirection,
      videoDimensions
    });
    configureSweepLine();
  }, [lineOrientation, lineDirection, configureSweepLine]);

  // Gestionnaire pour ajuster le compte des quantités
  const handleCountAdjustment = (delta) => {
    if (!selectedProduct) return;
    
    // Mettre à jour l'état local d'abord
    const newCount = Math.max(0, currentCount + delta);
    setCurrentCount(newCount);
    setManualAdjustment(prev => prev + delta);
  
    // Envoyer le message d'ajustement via WebSocket
    sendMessage({
      type: "adjusted_quantity",
      data: {
        product_name: selectedProduct.name,
        increment: delta,  // Envoi de l'incrément plutôt qu'une valeur absolue
        adjustment_reason: delta > 0 ? "manual_increment" : "manual_decrement",
        user_id: userContext?.user_id,
        sub: userContext?.sub,
        inventory_session_id: inventorySessionId
      }
    });
  };

  // Gestionnaire pour envoyer l'inventaire
  const handleSendInventory = async () => {
    if (!selectedProduct || currentCount === 0 || isSending || isCounting) {
        return;
    }

    setIsSending(true);
    console.log('[SweepSession] Sending inventory update:', {
        product_name: selectedProduct.name,
        count: currentCount,
        user_id: userContext?.user_id
    });

    sendMessage({
        type: "send_counting_inventory",
        data: {
            product_name: selectedProduct.name,
            count: currentCount,
            user_id: userContext?.user_id,
            inventory_session_id: inventorySessionId
        }
    });
  };

  // Ajouter la fonction de gestion du bouton de capture
  const handleCountButtonPress = () => {
    setIsCountButtonPressed(!isCountButtonPressed);
  };

  const handleOrientationChange = (newOrientation, newDirection) => {
    const newPosition = getDefaultPosition(newDirection);
    console.log("[SweepSession] Changing configuration:", {
        orientation: newOrientation,
        direction: newDirection,
        position: newPosition
    });
    
    // Mise à jour synchrone des états
    setLineOrientation(newOrientation);
    setLineDirection(newDirection);
    setLinePosition(newPosition);
    
    // Envoi d'une seule configuration
    sendMessage({
        type: "configure_sweep_line",
        data: {
            orientation: newOrientation,
            direction: newDirection,
            position: newPosition,
            width: videoDimensions.width || 640,
            height: videoDimensions.height || 640,
            inventory_session_id: inventorySessionId
        }
    });
  };

  // Fonction utilitaire pour obtenir la position par défaut selon la direction
  const getDefaultPosition = (direction) => {
    const positions = {
      'up_to_down': 75.0,    // Quart inférieur
      'down_to_up': 25.0,    // Quart supérieur
      'left_to_right': 75.0, // Quart droit
      'right_to_left': 25.0  // Quart gauche
    };
    return positions[direction] || 75.0;  // Par défaut, quart inférieur
  };

  // Return principal du composant SweepSession
  return (
    <div className="min-vh-100 is-flex is-flex-direction-column" style={{ backgroundColor: '#192066' }}>
      {/* Notification */}
      {notification.isVisible && (
        <div 
          className={`notification ${notification.type} is-light`}
          style={{
            position: 'fixed',
            top: '1rem',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            minWidth: '200px',
            maxWidth: '80%',
            margin: '0 auto',
            textAlign: 'center',
            animation: 'slideDown 0.5s ease-out',
            padding: '0.75rem 1.5rem'
          }}
        >
          {notification.message}
        </div>
      )}

      {/* Header */}
      <div className="is-flex is-align-items-center py-1 px-2 is-justify-content-space-between has-background-white">
        <div className="is-flex is-align-items-center">
          <button className="button is-small mr-2 is-light" onClick={onBack}>
            <span className="icon is-small">
              <i className="fas fa-chevron-left"></i>
            </span>
          </button>
          <h1 className="title is-6 mb-0">Sweep Mode</h1>
        </div>
        
        <button 
          className="button is-small is-light"
          onClick={() => setShowStats(!showStats)}
        >
          <span className="icon">
            <i className="fas fa-info-circle"></i>
          </span>
        </button>
      </div>
  
      {/* Stats Panel */}
      <div className={`px-2 ${!showStats && 'is-hidden'}`}>
        <StatsPanel stats={streamStats} smoothedLatency={smoothedLatency} />
      </div>
  
      {/* Main Content Container */}
      <div className="is-flex is-flex-direction-column" style={{ flex: 1, width: '100%' }}>
        {/* Video Container */}
        <div className="mb-2">
          <div className="box has-background-white p-2 mb-2" style={{ 
            marginLeft: 0,        // Supprime la marge à gauche
            marginRight: 0,       // Supprime la marge à droite
            borderRadius: '0 0 12px 12px', // Coins arrondis uniquement en bas
            borderTop: 'none'     // Supprime la bordure du haut
          }}>
            <div 
              className="has-background-black" 
              style={{ 
                borderRadius: '0 0 8px 8px',  // Coins arrondis uniquement en bas pour la zone noire
                overflow: 'hidden',
                transition: 'box-shadow 0.3s ease-in-out',
                boxShadow: isCountButtonPressed ? '0 0 0 4px rgba(255, 0, 0, 0.8)' : 'none'
              }}
            >
              <div 
                className="is-relative" 
                style={{ 
                  paddingTop: '100%',
                  backgroundColor: 'black'
                }}
              >
                <div 
                  style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%'
                  }}
                >
                  {shouldShowVideo && (
                    <VideoOverlay
                      websocket={socket}
                      inventorySessionId={inventorySessionId}
                      mode="sweep"
                      onVideoReady={() => setShowSpinner(false)}
                      userContext={userContext}
                      onStatsUpdate={handleStatsUpdate}
                      selectedProduct={selectedProduct}
                      isSelectionLocked={isSelectionLocked}
                    />
                  )}
                </div>
  
                {/* Line Controls - Boutons plus grands */}
                <div 
                  className="is-flex is-justify-content-center" 
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: '50%',
                    transform: 'translateX(-50%)',
                    zIndex: 10
                  }}
                >
                  <div className="buttons has-addons is-centered" style={{
                    borderRadius: '20px',
                    padding: '0.4rem'
                  }}>
                    <button
                      className={`button ${lineOrientation === 'horizontal' && lineDirection === 'down_to_up' ? 'is-info' : 'is-light'}`}
                      onPointerDown={() => handleOrientationChange('horizontal', 'down_to_up')}
                      style={{ height: '2.5rem', width: '2.5rem' }}
                    >
                      <span className="icon">
                        <i className="fas fa-arrows-up-to-line"></i>
                      </span>
                    </button>
                    <button
                      className={`button ${lineOrientation === 'horizontal' && lineDirection === 'up_to_down' ? 'is-info' : 'is-light'}`}
                      onPointerDown={() => handleOrientationChange('horizontal', 'up_to_down')}
                      style={{ height: '2.5rem', width: '2.5rem' }}
                    >
                      <span className="icon">
                        <i className="fas fa-arrows-down-to-line"></i>
                      </span>
                    </button>
                    <button
                      className={`button ${lineOrientation === 'vertical' && lineDirection === 'left_to_right' ? 'is-info' : 'is-light'}`}
                      onPointerDown={() => handleOrientationChange('vertical', 'left_to_right')}
                      style={{ height: '2.5rem', width: '2.5rem' }}
                    >
                      <span className="icon">
                        <i className="fas fa-arrow-right"></i>
                      </span>
                    </button>
                    <button
                      className={`button ${lineOrientation === 'vertical' && lineDirection === 'right_to_left' ? 'is-info' : 'is-light'}`}
                      onPointerDown={() => handleOrientationChange('vertical', 'right_to_left')}
                      style={{ height: '2.5rem', width: '2.5rem' }}
                    >
                      <span className="icon">
                        <i className="fas fa-arrow-left"></i>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        {/* Product Selection */}
        <div className="mb-2">
          <div className="box p-2 mb-2">
            <label className="label">Product Selection</label>
            <div className="field mb-2">
              <div className="is-flex" style={{ gap: '0.5rem' }}>
                <button 
                  className={`button ${isSelectionLocked ? 'is-danger' : 'is-success'}`} 
                  onClick={toggleLock}
                  disabled={!selectedProduct}
                  style={{ height: '2.5rem', width: '2.5rem' }}
                >
                  <span className="icon">
                    <i className={`fas fa-${isSelectionLocked ? 'lock' : 'lock-open'} fa-lg`}></i>
                  </span>
                </button>
                <div className="select is-fullwidth">
                  <select 
                    value={selectedProduct?.id || ''} 
                    onChange={handleProductChange}
                    disabled={isSelectionLocked}
                    style={{ height: '2.5rem' }}
                  >
                    <option value="">Select a product...</option>
                    {products.map(product => (
                      <option key={product.id} value={product.id}>
                        {product.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
  
            <div className="is-flex is-align-items-center is-justify-content-space-between">
              <div className="is-flex is-align-items-center" style={{ gap: '0.5rem' }}>
                <button 
                  className="button is-light is-rounded" 
                  onClick={() => handleCountAdjustment(-1)}
                  disabled={currentCount === 0 || !selectedProduct || isCountButtonPressed}
                >
                  <i className="fas fa-minus"></i>
                </button>
                
                <div className="title is-5 mb-0">{currentCount}</div>
                
                <button 
                  className="button is-light is-rounded" 
                  onClick={() => handleCountAdjustment(1)}
                  disabled={!selectedProduct || isCountButtonPressed}
                >
                  <i className="fas fa-plus"></i>
                </button>
              </div>
  
              <button
                className={`button is-light is-rounded ${isSending ? 'is-loading' : ''}`}
                onClick={handleSendInventory}
                disabled={currentCount === 0 || !selectedProduct || isSending || isCounting}
              >
                <span className="icon">
                    <i className="fas fa-paper-plane"></i>
                </span>
                <span>{isSending ? 'Sending...' : 'Send'}</span>
              </button>
            </div>
          </div>
        </div>
  
        {/* Capture Button */}
        <div className="is-flex is-justify-content-center" style={{ marginTop: 'auto', paddingBottom: '1rem' }}>
          <button
            onPointerDown={handleCountStart}
            onPointerUp={handleCountEnd}
            onPointerLeave={handleCountEnd}
            className={`button is-rounded ${isCountButtonPressed ? 'is-danger' : 'is-light'}`}
            style={{
              width: '70px',
              height: '70px',
              border: '2px solid #4a4a4a'
            }}
            disabled={!selectedProduct || !isSelectionLocked}
          >
            <span className="icon">
              <i className="fas fa-circle-dot fa-lg"></i>
            </span>
          </button>
        </div>
      </div>
    </div>
  );

};

export default SweepSession;
