// StorageAreaSelection.js
import React from 'react';

const StorageAreaSelection = ({ storageAreas, onSelect }) => {
  return (
    <div>
      <h2 className="title is-3 has-text-centered">Select Storage Area</h2>
      <div className="columns is-multiline is-centered">
        {storageAreas.map((area) => (
          <div key={area.id} className="column is-one-third-desktop is-half-tablet">
            <div 
              className="card storage-area-card" 
              onClick={() => onSelect(area)}
            >
              <div className="card-content has-text-centered">
                <p className="title is-4">{area.name}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StorageAreaSelection;