// InventoryNavigation.js
import React, { useState, useCallback, useEffect } from 'react'; 
import { useWebSocket } from '../contexts/WebSocketContext';
import { SERVER_VIDEO_CONSTRAINTS } from '../config/videoConfig';
import EntitySelection from './EntitySelection'; 
import StorageAreaSelection from './StorageAreaSelection';
import RackSelection from './RackSelection';
import InventorySession from './InventorySession';
import CountingSession from './CountingSession';
import SweepSession from './SweepSession';
import ModeSelection from './ModeSelection';

const InventoryNavigation = ({ 
  userContext, 
  state, 
  setState, 
  startInventorySession,
  inventorySessionId,
  mode,
  onModeChange 
}) => {
  // console.log("[InventoryNavigation] Rendering with props:", {
  //   hasUserContext: !!userContext,
  //   state,
  //   hasStartInventorySession: !!startInventorySession,
  //   inventorySessionId
  // });

  // États
  const [sessionKey, setSessionKey] = useState(Date.now());
  const [countingSessionId, setCountingSessionId] = useState(null);
  const { socket, sendMessage, subscribe } = useWebSocket();

  // Gestion du changement de mode
  const handleModeChange = useCallback(async (newMode) => {
    console.log(`[InventoryNavigation] Changing mode to ${newMode}`);
    
    // Si une session est en cours, la terminer proprement
    if (inventorySessionId) {
      const message = {
        type: newMode === 'counting' ? "end_inventory_session" : "end_counting_session",
        data: { 
          inventory_session_id: inventorySessionId 
        }
      };
      sendMessage(message);
    }
  
    // Informer le parent du changement de mode
    if (typeof onModeChange === 'function') {
      onModeChange(newMode, null);
    }
  
  }, [sendMessage, inventorySessionId, onModeChange]);

  // Handler pour retourner du mode counting à la sélection
  const handleBackFromCounting = useCallback(async () => {
    try {
      // 1. Nettoyer explicitement l'ancienne session
      if (inventorySessionId) {
        console.log('[InventoryNavigation] Ending counting session:', inventorySessionId);
        await sendMessage({
          type: "end_counting_session",
          data: { 
            inventory_session_id: inventorySessionId 
          }
        });
        // Attendre un peu pour s'assurer que la session est bien terminée
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      
      // 2. Réinitialiser l'état localement
      setState(prev => ({
        ...prev,
        rack: null,
        zone: null
      }));
      
      // 3. Réinitialiser le mode au lieu de forcer tagzone
      onModeChange(null);  // Retour à la sélection du mode
    } catch (error) {
      console.error('Error cleaning up counting session:', error);
    }
  }, [setState, sendMessage, inventorySessionId, onModeChange]);

  // Handler pour démarrer une session tagzone
  const handleStartTagzoneSession = useCallback(async (rack) => {
    try {
      console.log('[InventoryNavigation] Starting new tagzone session for rack:', rack.id);
      
      // 1. Terminer proprement l'ancienne session si elle existe
      if (inventorySessionId) {
        console.log('[InventoryNavigation] Ending previous session:', inventorySessionId);
        await sendMessage({
          type: "end_inventory_session",
          data: {
            inventory_session_id: inventorySessionId
          }
        });
        // Attendre un peu que la session soit terminée
        await new Promise(resolve => setTimeout(resolve, 500));
      }
      
      // 2. Force la création d'une nouvelle session
      const sessionParams = {
        mode: 'tagzone',
        user_id: userContext?.user_id,
        width: SERVER_VIDEO_CONSTRAINTS.width.ideal,
        height: SERVER_VIDEO_CONSTRAINTS.height.ideal,
        user_agent: navigator.userAgent,
        codec: 'vp8',
        rack_id: rack.id
      };
  
      // 3. Créer une nouvelle session
      console.log('[InventoryNavigation] Creating new session with params:', sessionParams);
      const newSessionId = await startInventorySession(sessionParams);
      console.log('[InventoryNavigation] Created new tagzone session:', newSessionId);
  
      // 4. Informer explicitement le backend du changement de mode
      await sendMessage({
        type: 'change_mode',
        data: {
          mode: 'tagzone',
          inventory_session_id: newSessionId,
          rack_id: rack.id
        }
      });
  
      return newSessionId;
    } catch (error) {
      console.error('Failed to start tagzone session:', error);
      alert('Failed to start inventory session. Please try again.');
      throw error;
    }
  }, [userContext, startInventorySession, sendMessage, inventorySessionId]);

  // Gestion de la mise à jour des quantités
  const updateQuantity = (shelfId, productName, change) => {
    console.log(`Updating quantity for ${productName} on shelf ${shelfId} by ${change}`);
    setState(prevState => {
      const updatedRack = {...prevState.rack};
      const shelf = updatedRack.shelves.find(s => s.id === parseInt(shelfId));
      if (shelf) {
        const product = shelf.expected_products.find(p => p.name === productName);
        if (product) {
          product.current_quantity = (product.current_quantity || 0) + change;
        }
      }
      return {...prevState, rack: updatedRack};
    });
  };

  // Gestion du démarrage de session
  const handleStartSession = async (selectedMode) => {
    try {
      const newSessionId = await startInventorySession({ 
        mode: selectedMode,
        zone: state.zone,
        rack: state.rack,
        shelf: state.shelf
      });

      sendMessage({
        type: "change_mode",
        data: {
          mode: selectedMode,
          inventory_session_id: newSessionId
        }
      });

      setState(prev => ({
        ...prev,
        showZoneSelection: false
      }));

      console.log(`[InventoryNavigation] Started new session: ${newSessionId} in mode: ${selectedMode}`);
    } catch (error) {
      console.error('Failed to start inventory session:', error);
    }
  };

  // Fonctions de gestion des sélections
  const handleEntitySelect = (entity) => {
    setState({ 
      ...state, 
      selectedEntity: entity, 
      zone: null, 
      rack: null, 
      shelf: null 
    });
  };

  const handleStorageAreaSelect = (storageArea) => {
    setState({ 
      ...state, 
      zone: storageArea, 
      rack: null, 
      shelf: null 
    });
  };

  const handleRackSelect = (rack) => {
    setState({ ...state, rack, shelf: null });
  };

  const handleBackToStorageAreas = () => {
    setState({ ...state, zone: null, rack: null, shelf: null });
  };

  const handleBackToRacks = () => {
    setState({ ...state, rack: null, shelf: null });
  };

  // Vérification initiale du userContext
  if (!userContext) {
    console.log("[InventoryNavigation] Waiting for user context...");
    return <div className="notification is-info">Loading user context...</div>;
  }

  // Navigation conditionnelle modifiée
  if (!state.selectedEntity) {
    return (
      <EntitySelection
        entities={userContext.entities}
        onSelect={handleEntitySelect}
      />
    );
  }

  // Nouvelle étape : sélection du mode après l'entité
  if (!mode) {
    return (
      <ModeSelection 
        onModeSelect={async (selectedMode) => {
          
          if (selectedMode === 'counting' || selectedMode === 'sweep') {
            try {
              onModeChange(selectedMode);
            } catch (error) {
              console.error(`Error starting ${selectedMode} session:`, error);
            }
          } else {
            onModeChange(selectedMode);
          }
        }} 
      />
    );
  }

  // Pour les modes counting et sweep, afficher directement leurs composants
  if (mode === 'counting') {
    return (
      <CountingSession
        userContext={userContext}
        onBack={handleBackFromCounting}
        inventorySessionId={inventorySessionId}
        startInventorySession={startInventorySession}
      />
    );
  }

  if (mode === 'sweep') {
    return (
      <SweepSession
        userContext={userContext}
        onBack={handleBackFromCounting}
        inventorySessionId={inventorySessionId}
        startInventorySession={startInventorySession}
      />
    );
  }

  // Pour le mode tagzone, continuer avec le flux existant
  if (mode === 'tagzone') {
    if (!state.zone) {
      return (
        <StorageAreaSelection
          storageAreas={state.selectedEntity.storage_areas || []}
          onSelect={handleStorageAreaSelect}
        />
      );
    }

    if (!state.rack) {
      return (
        <RackSelection
          racks={state.zone.racks}
          onSelect={handleRackSelect}
          onBack={handleBackToStorageAreas}
        />
      );
    }
  }

  // Vérification finale des données nécessaires
  if (!state.rack?.id || !state.selectedEntity?.id) {
    console.error("[InventoryNavigation] Missing required rack or entity data:", {
      hasRack: !!state.rack,
      rackId: state.rack?.id,
      hasEntity: !!state.selectedEntity,
      entityId: state.selectedEntity?.id
    });
    return <div className="notification is-danger">Loading rack data...</div>;
  }

  return (
    <InventorySession
      key={sessionKey}
      rack={state.rack}
      onBack={handleBackToRacks}
      startInventorySession={() => handleStartTagzoneSession(state.rack)}
      updateQuantity={updateQuantity}
      inventorySessionId={inventorySessionId}
      userContext={userContext}
      entityId={state.selectedEntity.id}
      mode="tagzone"
    />
  );
};

export default InventoryNavigation;