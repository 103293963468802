// MissingProductModal.js

import React, { useState, useEffect, useMemo } from 'react';

const MissingProductModal = ({ 
  isOpen, 
  onClose, 
  shelves, 
  selectedShelfId,
  onSubmit,
  userContext,
  canSubmit = false,
  submissionResponse // Nouveau prop pour la réponse du backend
}) => {
  const [selectedShelf, setSelectedShelf] = useState(selectedShelfId || '');
  const [selectedProduct, setSelectedProduct] = useState('');
  const [quantity, setQuantity] = useState(1);
  const [comment, setComment] = useState('');
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Reset le shelf sélectionné quand selectedShelfId change
  useEffect(() => {
    setSelectedShelf(selectedShelfId || '');
  }, [selectedShelfId]);

  // Gérer la réponse du backend avec timing amélioré
  useEffect(() => {
    if (submissionResponse) {
      setSubmissionStatus(submissionResponse);
      setIsSubmitting(false);
      
      if (submissionResponse.type === 'success') {
        setTimeout(() => {
          setSubmissionStatus(null);
          onClose();
        }, 2500); // 2.5 secondes pour bien voir le message
      }
    }
  }, [submissionResponse, onClose]);

  const handleProductChange = (e) => {
    const productId = e.target.value;
    setSelectedProductId(productId);
    const product = sortedProducts.find(p => p.id.toString() === productId);
    setSelectedProduct(product ? product.description || product.name : '');
  };

  // Gestionnaire pour incrémenter/décrémenter la quantité
  const handleQuantityChange = (change) => {
    setQuantity(prev => {
      const newValue = prev + change;
      return Math.min(Math.max(0, newValue), 100); // Limiter entre 0 et 100
    });
  };

  // Organiser les étagères par zone
  const shelfGroups = useMemo(() => {
    if (!shelves) return {};
    
    const groups = {};
    shelves.forEach(shelf => {
      const parts = shelf.name.split('-');
      if (parts.length === 3) {
        const zoneLetter = parts[2][0];
        if (!groups[zoneLetter]) {
          groups[zoneLetter] = [];
        }
        groups[zoneLetter].push(shelf);
      }
    });

    Object.keys(groups).forEach(zone => {
      groups[zone].sort((a, b) => {
        const aNum = parseInt(a.name.slice(-1));
        const bNum = parseInt(b.name.slice(-1));
        return aNum - bNum;
      });
    });

    return Object.keys(groups)
      .sort((a, b) => b.localeCompare(a))
      .reduce((acc, key) => {
        acc[key] = groups[key];
        return acc;
      }, {});
  }, [shelves]);

  // Utiliser les produits de la racine du userContext
  const sortedProducts = useMemo(() => {
    // S'assurer que nous utilisons les produits de la racine
    const products = userContext?.products || [];
    // Trier les produits par description
    return [...products].sort((a, b) => 
      (a.description || a.name).localeCompare(b.description || b.name)
    );
  }, [userContext?.products]);

  // Log de debug pour vérifier les produits
  console.debug('Products disponibles:', sortedProducts);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!canSubmit) {
        alert('Error: No active inventory session. Please start a session first.');
        return;
    }

    if (isSubmitting) {
        return;
    }

    setIsSubmitting(true);
    setSubmissionStatus(null);

    try {
        await onSubmit({
            shelf_id: selectedShelf,
            product_id: selectedProductId,
            product_description: selectedProduct,
            missing_quantity: quantity,
            comment: comment,
            timestamp: new Date().toISOString(),
            user_id: userContext.user_id,
            user_name: userContext.user_name
        });
    } catch (error) {
        console.error('Error submitting missing product:', error);
        setSubmissionStatus({
            type: 'error',
            message: error.message || 'Failed to submit missing product report.'
        });
        setIsSubmitting(false);
        // Ajouter un timeout pour fermer la modale en cas d'erreur
        setTimeout(() => {
            onClose();
        }, 3000);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={onClose}></div>
      <div className="modal-card" style={{ 
        maxWidth: '600px', 
        margin: 'auto',
        height: '100vh',  // Utiliser toute la hauteur sur mobile
        display: 'flex',
        flexDirection: 'column'
      }}>
        <header className="modal-card-head" style={{ position: 'sticky', top: 0, zIndex: 1 }}>
          <p className="modal-card-title">
            <span className="icon-text">
              <span className="icon">
                <i className="fas fa-exclamation-triangle text-warning"></i>
              </span>
              <span>Report Missing Product</span>
            </span>
          </p>
          <button 
            className="delete" 
            aria-label="close" 
            onClick={onClose}
            disabled={isSubmitting}
          ></button>
        </header>
        
        <section className="modal-card-body" style={{ flex: 1, overflowY: 'auto' }}>
          {submissionStatus && (
            <div className={`notification ${
              submissionStatus.type === 'success' ? 'is-success' : 'is-danger'
            } is-light has-text-centered mb-4`}>
              <p className="is-size-5">
                <span className="icon">
                  <i className={`fas ${
                    submissionStatus.type === 'success' ? 'fa-check-circle' : 'fa-exclamation-circle'
                  }`}></i>
                </span>
                <span>{submissionStatus.message}</span>
              </p>
            </div>
          )}

          <form onSubmit={handleSubmit}>
            {/* Sélection de l'étagère */}
            <div className="field">
              <label className="label">Select Location</label>
              <div className="shelf-zones">
                {Object.entries(shelfGroups).map(([zone, zoneShelvess]) => (
                  <div key={zone} className="mb-4">
                    <p className="subtitle is-6 mb-2">Zone {zone}</p>
                    <div className="buttons are-small">
                      {zoneShelvess.map(shelf => (
                        <button
                          key={shelf.id}
                          type="button"
                          onClick={() => setSelectedShelf(shelf.id)}
                          className={`button ${
                            selectedShelf === shelf.id 
                              ? 'is-success' 
                              : 'is-light'
                          }`}
                          style={{
                            width: '60px',
                            height: '60px',
                            margin: '4px',
                            padding: '0',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                          }}
                          disabled={isSubmitting}
                        >
                          {shelf.name.split('-')[2]}
                        </button>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Sélection du produit */}
            <div className="field">
              <label className="label">Missing Product</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    value={selectedProductId || ''}
                    onChange={handleProductChange}
                    required
                    disabled={isSubmitting}
                  >
                    <option value="">Select a product</option>
                    {sortedProducts.map((product) => (
                      <option key={product.id} value={product.id}>
                        {product.description || product.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* Sélection de la quantité avec contrôles + et - */}
            <div className="field">
              <label className="label">Missing Quantity</label>
              <div className="field has-addons">
                <p className="control">
                  <button
                    type="button"
                    className="button is-info"
                    onClick={() => handleQuantityChange(-1)}
                    disabled={isSubmitting}
                  >
                    <span className="icon">
                      <i className="fas fa-minus"></i>
                    </span>
                  </button>
                </p>
                <p className="control is-expanded">
                  <div className="select is-fullwidth">
                    <select
                      value={quantity}
                      onChange={(e) => setQuantity(parseInt(e.target.value))}
                      required
                      disabled={isSubmitting}
                    >
                      {Array.from({ length: 101 }, (_, i) => (
                        <option key={i} value={i}>{i}</option>
                      ))}
                    </select>
                  </div>
                </p>
                <p className="control">
                  <button
                    type="button"
                    className="button is-info"
                    onClick={() => handleQuantityChange(1)}
                    disabled={isSubmitting}
                  >
                    <span className="icon">
                      <i className="fas fa-plus"></i>
                    </span>
                  </button>
                </p>
              </div>
            </div>

            {/* Commentaire */}
            <div className="field">
              <label className="label">Comment (optional)</label>
              <div className="control">
                <textarea
                  className="textarea"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Add any relevant details..."
                  rows="2"
                  disabled={isSubmitting}
                />
              </div>
            </div>
          </form>
        </section>

        <footer className="modal-card-foot" style={{ 
          position: 'sticky', 
          bottom: 0, 
          zIndex: 1,
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
          padding: '1rem'
        }}>
          <button 
            className={`button is-success ${isSubmitting ? 'is-loading' : ''}`}
            onClick={handleSubmit}
            disabled={!selectedShelf || !selectedProductId || !canSubmit || isSubmitting}
            style={{ width: '100%' }}
          >
            <span className="icon">
              <i className={`fas ${isSubmitting ? 'fa-spinner fa-spin' : 'fa-check'}`}></i>
            </span>
            <span>{isSubmitting ? 'Submitting...' : 'Submit'}</span>
          </button>

          <button 
            className="button is-light"
            onClick={onClose}
            disabled={isSubmitting}
            style={{ width: '100%' }}
          >
            <span className="icon">
              <i className="fas fa-times"></i>
            </span>
            <span>Cancel</span>
          </button>
        </footer>

        {!canSubmit && (
          <div className="notification is-warning is-light m-4">
            <p className="has-text-centered">
              <span className="icon">
                <i className="fas fa-exclamation-circle"></i>
              </span>
              <span>An active inventory session is required to report missing products.</span>
            </p>
          </div>
        )}

        {isSubmitting && (
          <div className="notification is-info is-light m-4">
            <div className="is-flex is-align-items-center is-justify-content-center">
              <span className="icon mr-2">
                <i className="fas fa-spinner fa-spin"></i>
              </span>
              <span>Submitting your report...</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MissingProductModal;